import { Injectable } from '@angular/core';
import { SelectItem, SelectItemGroup } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  regiones = [
    { region: 'Región Eje Cafetero - Antioquia', codRegion: '1' },
    { region: 'Región Centro Oriente', codRegion: '2' },
    { region: 'Región Caribe', codRegion: '3' },
    { region: 'Región Llano', codRegion: '4' },
    { region: 'Región Centro Sur', codRegion: '5' },
    { region: 'Región Pacífico', codRegion: '6' },
  ];

  departamentos = [
    {
      codDepartamento: '91',
      departamento: 'Amazonas',
      Lat: '-1.0197222',
      Lng: '-71.9383333',
    },
    { codDepartamento: '5', departamento: 'Antioquia', Lat: '7', Lng: '-75.5' },
    {
      codDepartamento: '81',
      departamento: 'Arauca',
      Lat: '7.0902778',
      Lng: '-70.7616667',
    },
    {
      codDepartamento: '8',
      departamento: 'Atlántico',
      Lat: '10.75',
      Lng: '-75',
    },
    {
      codDepartamento: '11',
      departamento: 'Bogotá D.C',
      Lat: '4.6',
      Lng: '-74.0833333',
    },
    {
      codDepartamento: '13',
      departamento: 'Bolí­var',
      Lat: '9',
      Lng: '-74.3333333',
    },
    { codDepartamento: '15', departamento: 'Boyacá', Lat: '5.5', Lng: '-72.5' },
    {
      codDepartamento: '17',
      departamento: 'Caldas',
      Lat: '5.25',
      Lng: '-75.5',
    },
    { codDepartamento: '18', departamento: 'Caquetá', Lat: '1', Lng: '-74' },
    {
      codDepartamento: '85',
      departamento: 'Casanare',
      Lat: '5.5',
      Lng: '-71.5',
    },
    {
      codDepartamento: '19',
      departamento: 'Cauca',
      Lat: '2.5',
      Lng: '-76.8333333',
    },
    {
      codDepartamento: '20',
      departamento: 'Cesar',
      Lat: '9.3333333',
      Lng: '-73.5',
    },
    { codDepartamento: '27', departamento: 'Chocó', Lat: '6', Lng: '-77' },
    {
      codDepartamento: '25',
      departamento: 'Cundinamarca',
      Lat: '5',
      Lng: '-74.1666667',
    },
    {
      codDepartamento: '23',
      departamento: 'Córdoba',
      Lat: '8.3333333',
      Lng: '-75.6666667',
    },
    { codDepartamento: '94', departamento: 'Guainí­a', Lat: '2.5', Lng: '-69' },
    {
      codDepartamento: '95',
      departamento: 'Guaviare',
      Lat: '1.6894444',
      Lng: '-72.8202778',
    },
    { codDepartamento: '41', departamento: 'Huila', Lat: '2.5', Lng: '-75.75' },
    {
      codDepartamento: '44',
      departamento: 'La Guajira',
      Lat: '11.5',
      Lng: '-72.5',
    },
    {
      codDepartamento: '47',
      departamento: 'Magdalena',
      Lat: '10',
      Lng: '-74.5',
    },
    { codDepartamento: '50', departamento: 'Meta', Lat: '3.5', Lng: '-73' },
    { codDepartamento: '52', departamento: 'Nariño', Lat: '1.5', Lng: '-78' },
    {
      codDepartamento: '54',
      departamento: 'Norte de Santander',
      Lat: '8',
      Lng: '-73',
    },
    { codDepartamento: '86', departamento: 'Putumayo', Lat: '0.5', Lng: '-76' },
    {
      codDepartamento: '63',
      departamento: 'Quindí­o',
      Lat: '4.5',
      Lng: '-75.6666667',
    },
    { codDepartamento: '66', departamento: 'Risaralda', Lat: '5', Lng: '-76' },
    {
      codDepartamento: '88',
      departamento: 'San Andrés',
      Lat: '12.5847222',
      Lng: '-81.7005556',
    },
    {
      codDepartamento: '68',
      departamento: 'Santander',
      Lat: '7',
      Lng: '-73.25',
    },
    { codDepartamento: '70', departamento: 'Sucre', Lat: '9', Lng: '-75' },
    {
      codDepartamento: '73',
      departamento: 'Tolima',
      Lat: '3.75',
      Lng: '-75.25',
    },
    {
      codDepartamento: '76',
      departamento: 'Valle del Cauca',
      Lat: '3.75',
      Lng: '-76.5',
    },
    {
      codDepartamento: '97',
      departamento: 'Vaupés',
      Lat: '0.25',
      Lng: '-70.75',
    },
    { codDepartamento: '99', departamento: 'Vichada', Lat: '5', Lng: '-69.5' },
  ];

  municipios = [
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,001',
      municipio: 'Medellín',
      Lat: '6.2913889',
      Lng: '-75.5361111',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,002',
      municipio: 'Abejorral',
      Lat: '5.75',
      Lng: '-75.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,004',
      municipio: 'Abriaquí',
      Lat: '6.6666667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,021',
      municipio: 'Alejandría',
      Lat: '6.3666667',
      Lng: '-75.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,03',
      municipio: 'Amagá',
      Lat: '6.05',
      Lng: '-75.7',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,031',
      municipio: 'Amalfi',
      Lat: '7',
      Lng: '-74.9166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,034',
      municipio: 'Andes',
      Lat: '5.5833333',
      Lng: '-75.9166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,036',
      municipio: 'Angelópolis',
      Lat: '6.1333333',
      Lng: '-75.7',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,038',
      municipio: 'Angostura',
      Lat: '6.8666667',
      Lng: '-75.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,04',
      municipio: 'Anorí',
      Lat: '7.1666667',
      Lng: '-75.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,176',
      municipio: 'Chimá',
      Lat: '9.0833333',
      Lng: '-75.6333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,044',
      municipio: 'Anza',
      Lat: '4.7972222',
      Lng: '-75.995',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,045',
      municipio: 'Apartadó',
      Lat: '7.8855556',
      Lng: '-76.6347222',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,051',
      municipio: 'Arboletes',
      Lat: '8.5',
      Lng: '-76.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,055',
      municipio: 'Argelia',
      Lat: '4.7',
      Lng: '-76.1333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,059',
      municipio: 'Armenia',
      Lat: '4.5338889',
      Lng: '-75.6811111',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,079',
      municipio: 'Barbosa',
      Lat: '6',
      Lng: '-73.55',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,088',
      municipio: 'Bello',
      Lat: '6.3388889',
      Lng: '-75.5622222',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,091',
      municipio: 'Betania',
      Lat: '5.75',
      Lng: '-75.9666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,093',
      municipio: 'Betulia',
      Lat: '7.0833333',
      Lng: '-73.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,101',
      municipio: 'Ciudad Bolívar',
      Lat: '8.8333333',
      Lng: '-75.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,107',
      municipio: 'Briceño',
      Lat: '5.75',
      Lng: '-73.8333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,113',
      municipio: 'Buriticá',
      Lat: '4.2094444',
      Lng: '-76.1602778',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,12',
      municipio: 'Cáceres',
      Lat: '4.2902778',
      Lng: '-72.7905556',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,125',
      municipio: 'Caicedo',
      Lat: '6.45',
      Lng: '-75.9666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,129',
      municipio: 'Caldas',
      Lat: '5.55',
      Lng: '-73.8833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,134',
      municipio: 'Campamento',
      Lat: '7.0833333',
      Lng: '-75.25',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,138',
      municipio: 'Cañasgordas',
      Lat: '5.3388889',
      Lng: '-76.7313889',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,142',
      municipio: 'Caracolí',
      Lat: '6.4119444',
      Lng: '-74.7605556',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,145',
      municipio: 'Caramanta',
      Lat: '5.5833333',
      Lng: '-75.5833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,147',
      municipio: 'Carepa',
      Lat: '7.7663889',
      Lng: '-76.6611111',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,67',
      municipio: 'Sampués',
      Lat: '9.1666667',
      Lng: '-75.3',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,15',
      municipio: 'Carolina',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,154',
      municipio: 'Caucasia',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,172',
      municipio: 'Chigorodó',
      Lat: '7.6666667',
      Lng: '-76.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,19',
      municipio: 'Cisneros',
      Lat: '8.8333333',
      Lng: '-75.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,197',
      municipio: 'Cocorná',
      Lat: '6',
      Lng: '-75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,206',
      municipio: 'Concepción',
      Lat: '6.9166667',
      Lng: '-72.5',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,209',
      municipio: 'Concordia',
      Lat: '9.8402778',
      Lng: '-74.4447222',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,212',
      municipio: 'Copacabana',
      Lat: '6.3333333',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,234',
      municipio: 'Dabeiba',
      Lat: '7',
      Lng: '-76.25',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,237',
      municipio: 'Don Matías',
      Lat: '6.5',
      Lng: '-75.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,24',
      municipio: 'Ebéjico',
      Lat: '6.3333333',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,25',
      municipio: 'El Bagre',
      Lat: '7.5941667',
      Lng: '-74.8119444',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,264',
      municipio: 'Entrerrios',
      Lat: '6.75',
      Lng: '-72.6333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,266',
      municipio: 'Envigado',
      Lat: '6.1730556',
      Lng: '-75.5638889',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,282',
      municipio: 'Fredonia',
      Lat: '5.9166667',
      Lng: '-75.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,306',
      municipio: 'Giraldo',
      Lat: '6.6666667',
      Lng: '-75.9166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,308',
      municipio: 'Girardota',
      Lat: '6.4166667',
      Lng: '-75.45',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,31',
      municipio: 'Gómez Plata',
      Lat: '7.0708333',
      Lng: '-73.1730556',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,225',
      municipio: 'Nunchía',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,315',
      municipio: 'Guadalupe',
      Lat: '6.3',
      Lng: '-73.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,318',
      municipio: 'Guarne',
      Lat: '6.25',
      Lng: '-75.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,321',
      municipio: 'Guatapé',
      Lat: '6.25',
      Lng: '-75.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,347',
      municipio: 'Heliconia',
      Lat: '6.2333333',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,353',
      municipio: 'Hispania',
      Lat: '5.8025',
      Lng: '-75.9116667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,36',
      municipio: 'Itagui',
      Lat: '6.1719444',
      Lng: '-75.6113889',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,361',
      municipio: 'Ituango',
      Lat: '7.25',
      Lng: '-76',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,518',
      municipio: 'Pamplona',
      Lat: '7.3780556',
      Lng: '-72.6525',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,368',
      municipio: 'Jericó',
      Lat: '6.2',
      Lng: '-72.5833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,376',
      municipio: 'La Ceja',
      Lat: '6',
      Lng: '-75.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,38',
      municipio: 'La Estrella',
      Lat: '6.1666667',
      Lng: '-75.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,39',
      municipio: 'La Pintada',
      Lat: '5.75',
      Lng: '-75.6',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,4',
      municipio: 'La Unión',
      Lat: '4.5358333',
      Lng: '-76.1066667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,411',
      municipio: 'Liborina',
      Lat: '4.9166667',
      Lng: '-75.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,425',
      municipio: 'Maceo',
      Lat: '6.5',
      Lng: '-74.75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,44',
      municipio: 'Marinilla',
      Lat: '6.2',
      Lng: '-75.3',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,467',
      municipio: 'Montebello',
      Lat: '5.9166667',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,475',
      municipio: 'Murindó',
      Lat: '6.7833333',
      Lng: '-72.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,48',
      municipio: 'Mutatá',
      Lat: '6.7833333',
      Lng: '-72.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,483',
      municipio: 'Nariño',
      Lat: '1.5',
      Lng: '-78',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,49',
      municipio: 'Necoclí',
      Lat: '8.4238889',
      Lng: '-76.7911111',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,495',
      municipio: 'Nechí',
      Lat: '8.0963889',
      Lng: '-74.7758333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,501',
      municipio: 'Olaya',
      Lat: '6.6666667',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,541',
      municipio: 'Peñol',
      Lat: '5.5',
      Lng: '-75.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,543',
      municipio: 'Peque',
      Lat: '7',
      Lng: '-75.8333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,576',
      municipio: 'Pueblorrico',
      Lat: '5.8',
      Lng: '-75.85',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,579',
      municipio: 'Puerto Berrío',
      Lat: '6.4166667',
      Lng: '-74.5',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,585',
      municipio: 'Puerto Nare',
      Lat: '6.1916667',
      Lng: '-74.5866667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,591',
      municipio: 'Puerto Triunfo',
      Lat: '5.8708333',
      Lng: '-74.6455556',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,604',
      municipio: 'Remedios',
      Lat: '7',
      Lng: '-74.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,607',
      municipio: 'Retiro',
      Lat: '6.0833333',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,615',
      municipio: 'Rionegro',
      Lat: '11.5444444',
      Lng: '-72.9072222',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,628',
      municipio: 'Sabanalarga',
      Lat: '4.8536111',
      Lng: '-73.0430556',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,631',
      municipio: 'Sabaneta',
      Lat: '6.15',
      Lng: '-75.6',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,642',
      municipio: 'Salgar',
      Lat: '6',
      Lng: '-76',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,019',
      municipio: 'Albán',
      Lat: '4.9166667',
      Lng: '-74.45',
    },
    {
      codRegion: '4',
      codDepartamento: '97',
      codMunicipio: '97,889',
      municipio: 'Yavaraté',
      Lat: '7',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,652',
      municipio: 'San Francisco',
      Lat: '1.1772222',
      Lng: '-76.8830556',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,656',
      municipio: 'San Jerónimo',
      Lat: '6.45',
      Lng: '-75.7',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,466',
      municipio: 'Montelíbano',
      Lat: '7.75',
      Lng: '-75.6666667',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,568',
      municipio: 'Puerto Asís',
      Lat: '0.5158333',
      Lng: '-76.495',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,66',
      municipio: 'San Luis',
      Lat: '4.1666667',
      Lng: '-75.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,664',
      municipio: 'San Pedro',
      Lat: '4',
      Lng: '-76.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,215',
      municipio: 'Corozal',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,667',
      municipio: 'San Rafael',
      Lat: '6.3',
      Lng: '-75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,67',
      municipio: 'San Roque',
      Lat: '6.4166667',
      Lng: '-74.8333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,674',
      municipio: 'San Vicente',
      Lat: '6.3333333',
      Lng: '-75.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,679',
      municipio: 'Santa Bárbara',
      Lat: '6.9927778',
      Lng: '-72.9102778',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,11',
      municipio: 'Buesaco',
      Lat: '1.25',
      Lng: '-77.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,69',
      municipio: 'Santo Domingo',
      Lat: '6.4722222',
      Lng: '-75.1647222',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,697',
      municipio: 'El Santuario',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,736',
      municipio: 'Segovia',
      Lat: '7.25',
      Lng: '-74.75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,761',
      municipio: 'Sopetrán',
      Lat: '6.5',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,789',
      municipio: 'Támesis',
      Lat: '6.4641667',
      Lng: '-71.7288889',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,79',
      municipio: 'Tarazá',
      Lat: '7.5833333',
      Lng: '-75.35',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,792',
      municipio: 'Tarso',
      Lat: '5.8333333',
      Lng: '-75.8333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,809',
      municipio: 'Titiribí',
      Lat: '6.0833333',
      Lng: '-75.8',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,819',
      municipio: 'Toledo',
      Lat: '7.3',
      Lng: '-72.25',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,837',
      municipio: 'Turbo',
      Lat: '1.1666667',
      Lng: '-77.5833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,842',
      municipio: 'Uramita',
      Lat: '6.8994444',
      Lng: '-76.1741667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,847',
      municipio: 'Urrao',
      Lat: '6.3333333',
      Lng: '-76.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,854',
      municipio: 'Valdivia',
      Lat: '7.3333333',
      Lng: '-75.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,856',
      municipio: 'Valparaíso',
      Lat: '1.1991667',
      Lng: '-75.7097222',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,858',
      municipio: 'Vegachí',
      Lat: '6.7730556',
      Lng: '-74.8016667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,861',
      municipio: 'Venecia',
      Lat: '5.9166667',
      Lng: '-75.75',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,139',
      municipio: 'Maní',
      Lat: '10.3927778',
      Lng: '-73.0325',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,885',
      municipio: 'Yalí',
      Lat: '6.8333333',
      Lng: '-74.75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,887',
      municipio: 'Yarumal',
      Lat: '7',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,89',
      municipio: 'Yolombó',
      Lat: '6.6666667',
      Lng: '-75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,893',
      municipio: 'Yondó',
      Lat: '6.6666667',
      Lng: '-75',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,895',
      municipio: 'Zaragoza',
      Lat: '7.75',
      Lng: '-74.75',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,001',
      municipio: 'Barranquilla',
      Lat: '10.9638889',
      Lng: '-74.7963889',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,078',
      municipio: 'Baranoa',
      Lat: '10.8',
      Lng: '-74.9166667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,268',
      municipio: 'El Peñón',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,141',
      municipio: 'Candelaria',
      Lat: '3.4130556',
      Lng: '-76.3511111',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,296',
      municipio: 'Galapa',
      Lat: '6.7',
      Lng: '-73.3',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,834',
      municipio: 'Tuluá',
      Lat: '9.1833333',
      Lng: '-75.55',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,152',
      municipio: 'Casabianca',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,421',
      municipio: 'Luruaco',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,433',
      municipio: 'Malambo',
      Lat: '10.8588889',
      Lng: '-74.7730556',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,436',
      municipio: 'Manatí',
      Lat: '10.5',
      Lng: '-75',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,04',
      municipio: 'Anolaima',
      Lat: '4.8333333',
      Lng: '-74.5',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,549',
      municipio: 'Piojó',
      Lat: '10.75',
      Lng: '-75.1333333',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,558',
      municipio: 'Polonuevo',
      Lat: '10.75',
      Lng: '-74.7833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,175',
      municipio: 'Chía',
      Lat: '7.3',
      Lng: '-72.9666667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,835',
      municipio: 'San Andrés de Tumaco',
      Lat: '6917',
      Lng: '-75.667',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,634',
      municipio: 'Sabanagrande',
      Lat: '10.8',
      Lng: '-74.75',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,638',
      municipio: 'Sabanalarga',
      Lat: '4.8536111',
      Lng: '-73.0430556',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,675',
      municipio: 'Santa Lucía',
      Lat: '10.3286111',
      Lng: '-74.9644444',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,685',
      municipio: 'Santo Tomás',
      Lat: '10.75',
      Lng: '-74.8333333',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,758',
      municipio: 'Soledad',
      Lat: '10.9172222',
      Lng: '-74.7666667',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,77',
      municipio: 'Suan',
      Lat: '10.3333333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,832',
      municipio: 'Tubará',
      Lat: '10.9166667',
      Lng: '-74.95',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,849',
      municipio: 'Usiacurí',
      Lat: '10.75',
      Lng: '-74.9666667',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,46',
      municipio: 'Milán',
      Lat: '1.3483333',
      Lng: '-75.5116667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,147',
      municipio: 'Capitanejo',
      Lat: '6.6',
      Lng: '-72.6333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,006',
      municipio: 'Achí',
      Lat: '8.25',
      Lng: '-74.5',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,043',
      municipio: 'Anzoátegui',
      Lat: '6.3333333',
      Lng: '-75.9166667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,042',
      municipio: 'Arenal',
      Lat: '8.4666667',
      Lng: '-73.95',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,052',
      municipio: 'Arjona',
      Lat: '10.1666667',
      Lng: '-75.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,062',
      municipio: 'Arroyohondo',
      Lat: '10.2497222',
      Lng: '-75.0113889',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,275',
      municipio: 'Florida',
      Lat: '5.8047222',
      Lng: '-73.9741667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,14',
      municipio: 'Calamar',
      Lat: '1.9597222',
      Lng: '-72.6538889',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,16',
      municipio: 'Cantagallo',
      Lat: '7.3791667',
      Lng: '-73.9180556',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,188',
      municipio: 'Cicuco',
      Lat: '9.4166667',
      Lng: '-74.7333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,212',
      municipio: 'Córdoba',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,222',
      municipio: 'Clemencia',
      Lat: '10.5891667',
      Lng: '-75.3341667',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,606',
      municipio: 'Repelón',
      Lat: '10.55',
      Lng: '-75.1333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,248',
      municipio: 'El Guamo',
      Lat: '10.0833333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,284',
      municipio: 'Frontino',
      Lat: '6.6666667',
      Lng: '-76.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,43',
      municipio: 'Magangué',
      Lat: '9.2413889',
      Lng: '-74.7533333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,433',
      municipio: 'Mahates',
      Lat: '10.1666667',
      Lng: '-75.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,44',
      municipio: 'Margarita',
      Lat: '9.0833333',
      Lng: '-74.2',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,458',
      municipio: 'Montecristo',
      Lat: '8.2994444',
      Lng: '-74.4755556',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,468',
      municipio: 'Mompós',
      Lat: '9.25',
      Lng: '-74.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,473',
      municipio: 'Morales',
      Lat: '2.8',
      Lng: '-76.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,49',
      municipio: 'Norosí',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,549',
      municipio: 'Pinillos',
      Lat: '8.9172222',
      Lng: '-74.4663889',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,58',
      municipio: 'Regidor',
      Lat: '8.6666667',
      Lng: '-73.8333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,6',
      municipio: 'Río Viejo',
      Lat: '7.2191667',
      Lng: '-73.1558333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,647',
      municipio: 'San Estanislao',
      Lat: '10.3983333',
      Lng: '-75.1511111',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,65',
      municipio: 'San Fernando',
      Lat: '9.0833333',
      Lng: '-74.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,258',
      municipio: 'El Peñón',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,52',
      municipio: 'Pamplonita',
      Lat: '7.5',
      Lng: '-72.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,657',
      municipio: 'San Juan Nepomuceno',
      Lat: '10.8333333',
      Lng: '-73.0833333',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,46',
      municipio: 'Miriti Paraná',
      Lat: '3.25',
      Lng: '-76.25',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,4',
      municipio: 'Támara',
      Lat: '8.8333333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,673',
      municipio: 'Santa Catalina',
      Lat: '10.5833333',
      Lng: '-75.25',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,683',
      municipio: 'Santa Rosa',
      Lat: '1.5',
      Lng: '-76.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,806',
      municipio: 'Tibasosa',
      Lat: '5.8333333',
      Lng: '-72.9666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,744',
      municipio: 'Simití',
      Lat: '7.5833333',
      Lng: '-74.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,76',
      municipio: 'Soplaviento',
      Lat: '10.3333333',
      Lng: '-75.1',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,78',
      municipio: 'Talaigua Nuevo',
      Lat: '9.3069444',
      Lng: '-74.5686111',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,81',
      municipio: 'Tiquisio',
      Lat: '6.4233333',
      Lng: '-72.6952778',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,836',
      municipio: 'Turbaco',
      Lat: '1.1666667',
      Lng: '-77.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,838',
      municipio: 'Turbaná',
      Lat: '1.1666667',
      Lng: '-77.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,873',
      municipio: 'Villanueva',
      Lat: '5.2833333',
      Lng: '-71.9666667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,517',
      municipio: 'Páez',
      Lat: '3.0666667',
      Lng: '-76.3166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,001',
      municipio: 'Tunja',
      Lat: '5.5352778',
      Lng: '-73.3677778',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,022',
      municipio: 'Almeida',
      Lat: '4.9166667',
      Lng: '-73.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,047',
      municipio: 'Aquitania',
      Lat: '5.5197222',
      Lng: '-72.8875',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,051',
      municipio: 'Arcabuco',
      Lat: '5.8',
      Lng: '-73.3833333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,001',
      municipio: 'Ibagué',
      Lat: '4.4388889',
      Lng: '-75.2322222',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,09',
      municipio: 'Berbeo',
      Lat: '5.3333333',
      Lng: '-73',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,092',
      municipio: 'Betéitiva',
      Lat: '6',
      Lng: '-72.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,097',
      municipio: 'Boavita',
      Lat: '6.4166667',
      Lng: '-72.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,104',
      municipio: 'Boyacá',
      Lat: '5.5',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,106',
      municipio: 'Briceño',
      Lat: '5.75',
      Lng: '-73.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,109',
      municipio: 'Buena Vista',
      Lat: '8.0833333',
      Lng: '-73',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,114',
      municipio: 'Busbanzá',
      Lat: '5.9166667',
      Lng: '-72.85',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,131',
      municipio: 'Caldas',
      Lat: '5.55',
      Lng: '-73.8833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,135',
      municipio: 'Campohermoso',
      Lat: '5.0833333',
      Lng: '-73',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,162',
      municipio: 'Cerinza',
      Lat: '6',
      Lng: '-72.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,172',
      municipio: 'Chinavita',
      Lat: '7.75',
      Lng: '-72.55',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,176',
      municipio: 'Chiquinquirá',
      Lat: '5.6333333',
      Lng: '-73.75',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,18',
      municipio: 'Chiscas',
      Lat: '6.75',
      Lng: '-72.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,183',
      municipio: 'Chita',
      Lat: '6.1666667',
      Lng: '-72.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,185',
      municipio: 'Chitaraque',
      Lat: '6.0833333',
      Lng: '-73.3666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,187',
      municipio: 'Chivatá',
      Lat: '5.5333333',
      Lng: '-73.2666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,204',
      municipio: 'Cómbita',
      Lat: '1.5833333',
      Lng: '-77',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,212',
      municipio: 'Coper',
      Lat: '5.4333333',
      Lng: '-74.0333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,215',
      municipio: 'Corrales',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,218',
      municipio: 'Covarachía',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,223',
      municipio: 'Cubará',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,224',
      municipio: 'Cucaita',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,226',
      municipio: 'Cuítiva',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,232',
      municipio: 'Chíquiza',
      Lat: '5.6333333',
      Lng: '-73.75',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,236',
      municipio: 'Chivor',
      Lat: '10.0280556',
      Lng: '-74.6436111',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,238',
      municipio: 'Duitama',
      Lat: '5.8269444',
      Lng: '-73.0202778',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,244',
      municipio: 'El Cocuy',
      Lat: '6.4166667',
      Lng: '-72.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,248',
      municipio: 'El Espino',
      Lat: '6.5833333',
      Lng: '-72.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,272',
      municipio: 'Firavitoba',
      Lat: '5.75',
      Lng: '-72.9666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,276',
      municipio: 'Floresta',
      Lat: '5.9166667',
      Lng: '-72.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,293',
      municipio: 'Gachantivá',
      Lat: '5.75',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,296',
      municipio: 'Gameza',
      Lat: '8.3333333',
      Lng: '-73.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,299',
      municipio: 'Garagoa',
      Lat: '5.1333333',
      Lng: '-73.3',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,317',
      municipio: 'Guacamayas',
      Lat: '6.5',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,322',
      municipio: 'Guateque',
      Lat: '5.0833333',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,325',
      municipio: 'Guayatá',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,332',
      municipio: 'Güicán',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,362',
      municipio: 'Iza',
      Lat: '7.25',
      Lng: '-76',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,367',
      municipio: 'Jenesano',
      Lat: '5.3888889',
      Lng: '-73.3680556',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,368',
      municipio: 'Jericó',
      Lat: '6.2',
      Lng: '-72.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,377',
      municipio: 'Labranzagrande',
      Lat: '5.5833333',
      Lng: '-72.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,38',
      municipio: 'La Capilla',
      Lat: '5.15',
      Lng: '-73.45',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,401',
      municipio: 'La Victoria',
      Lat: '4.5238889',
      Lng: '-76.0411111',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,573',
      municipio: 'Puerto Colombia',
      Lat: '11.0166667',
      Lng: '-74.8833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,083',
      municipio: 'Belén',
      Lat: '5',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,425',
      municipio: 'Macanal',
      Lat: '4.9166667',
      Lng: '-73.25',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,442',
      municipio: 'Maripí',
      Lat: '5.5833333',
      Lng: '-74',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,455',
      municipio: 'Miraflores',
      Lat: '1.3366667',
      Lng: '-71.9511111',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,464',
      municipio: 'Mongua',
      Lat: '5.8333333',
      Lng: '-72.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,466',
      municipio: 'Monguí',
      Lat: '5.75',
      Lng: '-72.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,469',
      municipio: 'Moniquirá',
      Lat: '5.9166667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,48',
      municipio: 'Muzo',
      Lat: '6.7833333',
      Lng: '-72.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,491',
      municipio: 'Nobsa',
      Lat: '5.8333333',
      Lng: '-72.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,494',
      municipio: 'Nuevo Colón',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,5',
      municipio: 'Oicatá',
      Lat: '5.6',
      Lng: '-73.3166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,507',
      municipio: 'Otanche',
      Lat: '5.75',
      Lng: '-74.25',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,511',
      municipio: 'Pachavita',
      Lat: '5.1333333',
      Lng: '-73.4',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,514',
      municipio: 'Páez',
      Lat: '3.0666667',
      Lng: '-76.3166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,516',
      municipio: 'Paipa',
      Lat: '5.8333333',
      Lng: '-73.1',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,518',
      municipio: 'Pajarito',
      Lat: '5.4166667',
      Lng: '-72.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,522',
      municipio: 'Panqueba',
      Lat: '6.5',
      Lng: '-72.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,531',
      municipio: 'Pauna',
      Lat: '5.75',
      Lng: '-73.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,533',
      municipio: 'Paya',
      Lat: '5.6666667',
      Lng: '-72.3816667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,758',
      municipio: 'Sopó',
      Lat: '4.9166667',
      Lng: '-73.95',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,542',
      municipio: 'Pesca',
      Lat: '6.25',
      Lng: '-75.25',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,55',
      municipio: 'Pisba',
      Lat: '10.75',
      Lng: '-75.1333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,572',
      municipio: 'Puerto Boyacá',
      Lat: '5.9780556',
      Lng: '-74.5897222',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,58',
      municipio: 'Quípama',
      Lat: '5.5222222',
      Lng: '-74.1808333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,599',
      municipio: 'Ramiriquí',
      Lat: '5.4166667',
      Lng: '-73.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,6',
      municipio: 'Ráquira',
      Lat: '5.4166667',
      Lng: '-73.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,621',
      municipio: 'Rondón',
      Lat: '5.4166667',
      Lng: '-73.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,632',
      municipio: 'Saboyá',
      Lat: '5.75',
      Lng: '-73.7',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,638',
      municipio: 'Sáchica',
      Lat: '5.75',
      Lng: '-73.7',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,646',
      municipio: 'Samacá',
      Lat: '5.5',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,66',
      municipio: 'San Eduardo',
      Lat: '5.2166667',
      Lng: '-73.1333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,15',
      municipio: 'Carmen del Darien',
      Lat: '6085',
      Lng: '-75.3386111',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,299',
      municipio: 'Gama',
      Lat: '4.75',
      Lng: '-73.6',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,673',
      municipio: 'San Mateo',
      Lat: '6.5',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,718',
      municipio: 'Sasaima',
      Lat: '5',
      Lng: '-74.45',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,24',
      municipio: 'Chachagüí',
      Lat: '1.35943053925138',
      Lng: '-77.2836685180664',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,686',
      municipio: 'Santana',
      Lat: '6.0572222',
      Lng: '-73.4822222',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,69',
      municipio: 'Santa María',
      Lat: '3',
      Lng: '-75.7',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,001',
      municipio: 'Cúcuta',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,696',
      municipio: 'Santa Sofía',
      Lat: '5.75',
      Lng: '-73.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,72',
      municipio: 'Sativanorte',
      Lat: '6.1666667',
      Lng: '-72.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,723',
      municipio: 'Sativasur',
      Lat: '6.1333333',
      Lng: '-72.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,74',
      municipio: 'Siachoque',
      Lat: '5.5833333',
      Lng: '-73.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,753',
      municipio: 'Soatá',
      Lat: '6.4166667',
      Lng: '-72.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,755',
      municipio: 'Socotá',
      Lat: '6.0833333',
      Lng: '-72.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,757',
      municipio: 'Socha',
      Lat: '6',
      Lng: '-72.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,759',
      municipio: 'Sogamoso',
      Lat: '5.7205556',
      Lng: '-72.9297222',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,761',
      municipio: 'Somondoco',
      Lat: '4.9877778',
      Lng: '-73.4361111',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,762',
      municipio: 'Sora',
      Lat: '5.5666667',
      Lng: '-73.4333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,763',
      municipio: 'Sotaquirá',
      Lat: '5.8333333',
      Lng: '-73.25',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,764',
      municipio: 'Soracá',
      Lat: '5.5',
      Lng: '-73.3166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,774',
      municipio: 'Susacón',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,776',
      municipio: 'Sutamarchán',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,778',
      municipio: 'Sutatenza',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,79',
      municipio: 'Tasco',
      Lat: '5.9166667',
      Lng: '-72.7',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,798',
      municipio: 'Tenza',
      Lat: '5.15',
      Lng: '-73.45',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,804',
      municipio: 'Tibaná',
      Lat: '5.3333333',
      Lng: '-73.3833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,808',
      municipio: 'Tinjacá',
      Lat: '5.6333333',
      Lng: '-73.65',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,81',
      municipio: 'Tipacoque',
      Lat: '6.4233333',
      Lng: '-72.6952778',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,814',
      municipio: 'Toca',
      Lat: '5.6666667',
      Lng: '-73.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,001',
      municipio: 'Cartagena',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,82',
      municipio: 'Tópaga',
      Lat: '5.8333333',
      Lng: '-72.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,822',
      municipio: 'Tota',
      Lat: '5.5',
      Lng: '-73',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,835',
      municipio: 'Turmequé',
      Lat: '1.1666667',
      Lng: '-77.5833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,313',
      municipio: 'Granada',
      Lat: '3.5386111',
      Lng: '-73.7005556',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,839',
      municipio: 'Tutazá',
      Lat: '1.1666667',
      Lng: '-77.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,842',
      municipio: 'Umbita',
      Lat: '4.7166667',
      Lng: '-75.75',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,861',
      municipio: 'Ventaquemada',
      Lat: '5.4166667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,879',
      municipio: 'Viracachá',
      Lat: '5.5',
      Lng: '-73.25',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,897',
      municipio: 'Zetaquira',
      Lat: '4.3983333',
      Lng: '-76.0772222',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,001',
      municipio: 'Manizales',
      Lat: '5.07',
      Lng: '-75.5205556',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,013',
      municipio: 'Aguadas',
      Lat: '5.6333333',
      Lng: '-75.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,042',
      municipio: 'Anserma',
      Lat: '5.25',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,05',
      municipio: 'Aranzazu',
      Lat: '5.3',
      Lng: '-75.45',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,088',
      municipio: 'Belalcázar',
      Lat: '5',
      Lng: '-75.8333333',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,174',
      municipio: 'Chinchiná',
      Lat: '7.75',
      Lng: '-72.55',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,272',
      municipio: 'Filadelfia',
      Lat: '5.3',
      Lng: '-75.6',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,38',
      municipio: 'La Dorada',
      Lat: '5.5333333',
      Lng: '-74.7',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,388',
      municipio: 'La Merced',
      Lat: '5.4019444',
      Lng: '-75.8847222',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,433',
      municipio: 'Manzanares',
      Lat: '4.8333333',
      Lng: '-72.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,442',
      municipio: 'Marmato',
      Lat: '10',
      Lng: '-75.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,446',
      municipio: 'Marulanda',
      Lat: '10',
      Lng: '-75.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,486',
      municipio: 'Neira',
      Lat: '5.1666667',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,495',
      municipio: 'Norcasia',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,513',
      municipio: 'Pácora',
      Lat: '5.25',
      Lng: '-74.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,524',
      municipio: 'Palestina',
      Lat: '1.75',
      Lng: '-76.0666667',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,541',
      municipio: 'Pensilvania',
      Lat: '5.5',
      Lng: '-75.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,614',
      municipio: 'Riosucio',
      Lat: '11.5444444',
      Lng: '-72.9072222',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,616',
      municipio: 'Risaralda',
      Lat: '5.1666667',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,653',
      municipio: 'Salamina',
      Lat: '10.5',
      Lng: '-74.7',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,662',
      municipio: 'Samaná',
      Lat: '5.5',
      Lng: '-73.5',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,665',
      municipio: 'San José',
      Lat: '5.0858333',
      Lng: '-75.7883333',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,777',
      municipio: 'Supía',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,867',
      municipio: 'Victoria',
      Lat: '4.9166667',
      Lng: '-74.55',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,873',
      municipio: 'Villamaría',
      Lat: '5',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,877',
      municipio: 'Viterbo',
      Lat: '6.0833333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,001',
      municipio: 'Florencia',
      Lat: '1.6944444',
      Lng: '-77.0758333',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,029',
      municipio: 'Albania',
      Lat: '1.4744444',
      Lng: '-77.0836111',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,72',
      municipio: 'Santa Bárbara de Pinto',
      Lat: '10.6',
      Lng: '-74.167',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,442',
      municipio: 'María la Baja',
      Lat: '9.0833333',
      Lng: '-74.2',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,205',
      municipio: 'Curillo',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,247',
      municipio: 'El Doncello',
      Lat: '1.6794444',
      Lng: '-75.2791667',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,256',
      municipio: 'El Paujil',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,479',
      municipio: 'Morelia',
      Lat: '1.4875',
      Lng: '-75.725',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,592',
      municipio: 'Puerto Rico',
      Lat: '2.9383333',
      Lng: '-73.2083333',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,41',
      municipio: 'La Montañita',
      Lat: '1.5833333',
      Lng: '-75.25',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,753',
      municipio: 'San Vicente del Caguán',
      Lat: '2.1525',
      Lng: '-74.7888889',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,756',
      municipio: 'Solano',
      Lat: '0.7147222',
      Lng: '-75.2486111',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,785',
      municipio: 'Solita',
      Lat: '0.9',
      Lng: '-75.623',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,86',
      municipio: 'Valparaíso',
      Lat: '1.1991667',
      Lng: '-75.7097222',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,001',
      municipio: 'Popayán',
      Lat: '3.2775',
      Lng: '-75.6213889',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,022',
      municipio: 'Almaguer',
      Lat: '1.9166667',
      Lng: '-76.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,05',
      municipio: 'Argelia',
      Lat: '4.7',
      Lng: '-76.1333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,075',
      municipio: 'Balboa',
      Lat: '4.9166667',
      Lng: '-75.95',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,1',
      municipio: 'Bolívar',
      Lat: '4.3333333',
      Lng: '-76.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,11',
      municipio: 'Buenos Aires',
      Lat: '2.9166667',
      Lng: '-76.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,13',
      municipio: 'Cajibío',
      Lat: '2.6666667',
      Lng: '-76.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,137',
      municipio: 'Caldono',
      Lat: '2.8333333',
      Lng: '-76.4166667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,142',
      municipio: 'Caloto',
      Lat: '3.4372222',
      Lng: '-76.5225',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,212',
      municipio: 'Corinto',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,256',
      municipio: 'El Tambo',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,29',
      municipio: 'Florencia',
      Lat: '1.6944444',
      Lng: '-77.0758333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,3',
      municipio: 'Guachené',
      Lat: '3.1333333',
      Lng: '-76.3833333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,318',
      municipio: 'Guapi',
      Lat: '4.0833333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,355',
      municipio: 'Inzá',
      Lat: '3.8652778',
      Lng: '-67.9238889',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,364',
      municipio: 'Jambaló',
      Lat: '2.85',
      Lng: '-76.3166667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,392',
      municipio: 'La Sierra',
      Lat: '2.25',
      Lng: '-76.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,397',
      municipio: 'La Vega',
      Lat: '5',
      Lng: '-74.35',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,418',
      municipio: 'López',
      Lat: '4.9166667',
      Lng: '-75.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,45',
      municipio: 'Mercaderes',
      Lat: '1.75',
      Lng: '-77.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,455',
      municipio: 'Miranda',
      Lat: '3.25',
      Lng: '-76.25',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,473',
      municipio: 'Morales',
      Lat: '2.8',
      Lng: '-76.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,513',
      municipio: 'Padilla',
      Lat: '3.0666667',
      Lng: '-76.3166667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,532',
      municipio: 'Patía',
      Lat: '4.3333333',
      Lng: '-74.2833333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,533',
      municipio: 'Piamonte',
      Lat: '7.7833333',
      Lng: '-75.2',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,548',
      municipio: 'Piendamó',
      Lat: '2.75',
      Lng: '-76.5',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,573',
      municipio: 'Puerto Tejada',
      Lat: '3.25',
      Lng: '-76.4166667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,585',
      municipio: 'Puracé',
      Lat: '0.9166667',
      Lng: '-77.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,622',
      municipio: 'Rosas',
      Lat: '7.4166667',
      Lng: '-77.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,25',
      municipio: 'El Peñón',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,364',
      municipio: 'Jardín',
      Lat: '5.5833333',
      Lng: '-75.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,701',
      municipio: 'Santa Rosa',
      Lat: '1.5',
      Lng: '-76.5',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,743',
      municipio: 'Silvia',
      Lat: '2.75',
      Lng: '-76.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,76',
      municipio: 'Sotara',
      Lat: '5.8333333',
      Lng: '-73.25',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,78',
      municipio: 'Suárez',
      Lat: '10.3333333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,785',
      municipio: 'Sucre',
      Lat: '9',
      Lng: '-75',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,807',
      municipio: 'Timbío',
      Lat: '2',
      Lng: '-75.9166667',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,809',
      municipio: 'Timbiquí',
      Lat: '2.4166667',
      Lng: '-76.75',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,821',
      municipio: 'Toribio',
      Lat: '5.4166667',
      Lng: '-74.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,824',
      municipio: 'Totoró',
      Lat: '2.5833333',
      Lng: '-76.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,845',
      municipio: 'Villa Rica',
      Lat: '7.8338889',
      Lng: '-72.4741667',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,001',
      municipio: 'Valledupar',
      Lat: '10.4769444',
      Lng: '-73.2505556',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,011',
      municipio: 'Aguachica',
      Lat: '8.3333333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,013',
      municipio: 'Agustín Codazzi',
      Lat: '9.9166667',
      Lng: '-73.25',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,032',
      municipio: 'Astrea',
      Lat: '9.5011111',
      Lng: '-73.9802778',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,045',
      municipio: 'Becerril',
      Lat: '10.9638889',
      Lng: '-74.7963889',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,06',
      municipio: 'Bosconia',
      Lat: '9.9761111',
      Lng: '-73.8902778',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,175',
      municipio: 'Chimichagua',
      Lat: '9.0833333',
      Lng: '-75.6333333',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,178',
      municipio: 'Chiriguaná',
      Lat: '9.5',
      Lng: '-73.4166667',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,228',
      municipio: 'Curumaní',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,238',
      municipio: 'El Copey',
      Lat: '10.1505556',
      Lng: '-73.965',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,25',
      municipio: 'El Paso',
      Lat: '9.6622222',
      Lng: '-73.7519444',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,295',
      municipio: 'Gamarra',
      Lat: '8.3333333',
      Lng: '-73.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,31',
      municipio: 'González',
      Lat: '8.4',
      Lng: '-73.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,383',
      municipio: 'La Gloria',
      Lat: '8.5833333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,364',
      municipio: 'Jamundí',
      Lat: '3.1666667',
      Lng: '-76.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,443',
      municipio: 'Manaure',
      Lat: '11.7791667',
      Lng: '-72.4494444',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,517',
      municipio: 'Pailitas',
      Lat: '8.9661111',
      Lng: '-73.6316667',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,55',
      municipio: 'Pelaya',
      Lat: '8.6916667',
      Lng: '-73.6663889',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,57',
      municipio: 'Pueblo Bello',
      Lat: '10.3666667',
      Lng: '-73.6333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,787',
      municipio: 'Tadó',
      Lat: '5.3333333',
      Lng: '-76.4166667',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,621',
      municipio: 'La Paz',
      Lat: '6.25',
      Lng: '-73.4666667',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,71',
      municipio: 'San Alberto',
      Lat: '7.7525',
      Lng: '-73.3891667',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,75',
      municipio: 'San Diego',
      Lat: '10.3375',
      Lng: '-73.1825',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,77',
      municipio: 'San Martín',
      Lat: '3.7',
      Lng: '-73.7',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,787',
      municipio: 'Tamalameque',
      Lat: '8.8333333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,001',
      municipio: 'Montería',
      Lat: '8.7575',
      Lng: '-75.89',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,068',
      municipio: 'Ayapel',
      Lat: '8.25',
      Lng: '-75.25',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,079',
      municipio: 'Buenavista',
      Lat: '9.3222222',
      Lng: '-74.9772222',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,09',
      municipio: 'Canalete',
      Lat: '8.6761111',
      Lng: '-76.2041667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,162',
      municipio: 'Cereté',
      Lat: '8.9166667',
      Lng: '-76',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,168',
      municipio: 'Chimá',
      Lat: '9.0833333',
      Lng: '-75.6333333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,182',
      municipio: 'Chinú',
      Lat: '9.0833333',
      Lng: '-75.3333333',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,23',
      municipio: 'Orocué',
      Lat: '4.7941667',
      Lng: '-71.34',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,3',
      municipio: 'Cotorra',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,411',
      municipio: 'Líbano',
      Lat: '4.9166667',
      Lng: '-75.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,417',
      municipio: 'Lorica',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,419',
      municipio: 'Los Córdobas',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,464',
      municipio: 'Momil',
      Lat: '9.2372222',
      Lng: '-75.6780556',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,5',
      municipio: 'Moñitos',
      Lat: '4.9166667',
      Lng: '-72.8333333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,555',
      municipio: 'Planeta Rica',
      Lat: '8.3333333',
      Lng: '-75.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,57',
      municipio: 'Pueblo Nuevo',
      Lat: '8.2333333',
      Lng: '-74.9666667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,574',
      municipio: 'Puerto Escondido',
      Lat: '8.95',
      Lng: '-76.25',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,885',
      municipio: 'Yacopí',
      Lat: '5.6666667',
      Lng: '-74.4166667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,586',
      municipio: 'Purísima',
      Lat: '6.5',
      Lng: '-73.1333333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,66',
      municipio: 'Sahagún',
      Lat: '8.8333333',
      Lng: '-75.4166667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,67',
      municipio: 'San Andrés Sotavento',
      Lat: '6917',
      Lng: '-75.667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,672',
      municipio: 'San Antero',
      Lat: '9.35',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,13',
      municipio: 'Calarcá',
      Lat: '4.5325',
      Lng: '-75.6436111',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,756',
      municipio: 'Sonsón',
      Lat: '5.75',
      Lng: '-75',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,245',
      municipio: 'El Carmen',
      Lat: '6,698',
      Lng: '-73.511',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,686',
      municipio: 'San Pelayo',
      Lat: '9',
      Lng: '-75.9166667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,807',
      municipio: 'Tierralta',
      Lat: '8.1736111',
      Lng: '-76.0591667',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,815',
      municipio: 'Tuchín',
      Lat: '9.1833333',
      Lng: '-75.55',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,855',
      municipio: 'Valencia',
      Lat: '8.3',
      Lng: '-76.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,408',
      municipio: 'Lérida',
      Lat: '5.3333333',
      Lng: '-73.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,035',
      municipio: 'Anapoima',
      Lat: '4.6166667',
      Lng: '-74.55',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,053',
      municipio: 'Arbeláez',
      Lat: '4.3',
      Lng: '-74.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,086',
      municipio: 'Beltrán',
      Lat: '1.4166667',
      Lng: '-75.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,095',
      municipio: 'Bituima',
      Lat: '4.9166667',
      Lng: '-74.55',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,099',
      municipio: 'Bojacá',
      Lat: '4.75',
      Lng: '-74.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,12',
      municipio: 'Cabrera',
      Lat: '6.6333333',
      Lng: '-73.2',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,123',
      municipio: 'Cachipay',
      Lat: '5.2666667',
      Lng: '-74.5666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,126',
      municipio: 'Cajicá',
      Lat: '5',
      Lng: '-74.05',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,148',
      municipio: 'Caparrapí',
      Lat: '5.4166667',
      Lng: '-74.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,151',
      municipio: 'Caqueza',
      Lat: '6.6',
      Lng: '-72.6333333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,35',
      municipio: 'La Apartada',
      Lat: '7',
      Lng: '-77.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,168',
      municipio: 'Chaguaní',
      Lat: '5',
      Lng: '-74.65',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,178',
      municipio: 'Chipaque',
      Lat: '4.5',
      Lng: '-74.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,181',
      municipio: 'Choachí',
      Lat: '4.8855556',
      Lng: '-73.3688889',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,183',
      municipio: 'Chocontá',
      Lat: '4.8855556',
      Lng: '-73.3688889',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,2',
      municipio: 'Cogua',
      Lat: '5.15',
      Lng: '-73.9666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,214',
      municipio: 'Cota',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,224',
      municipio: 'Cucunubá',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,245',
      municipio: 'El Colegio',
      Lat: '4.5833333',
      Lng: '-74.45',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,26',
      municipio: 'El Rosal',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,279',
      municipio: 'Fomeque',
      Lat: '5.8047222',
      Lng: '-73.9741667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,281',
      municipio: 'Fosca',
      Lat: '4.3333333',
      Lng: '-73.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,286',
      municipio: 'Funza',
      Lat: '4.7833333',
      Lng: '-74.2',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,288',
      municipio: 'Fúquene',
      Lat: '4.7833333',
      Lng: '-74.2',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,293',
      municipio: 'Gachala',
      Lat: '5.4166667',
      Lng: '-73.8',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,295',
      municipio: 'Gachancipá',
      Lat: '5.0833333',
      Lng: '-73.8833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,297',
      municipio: 'Gachetá',
      Lat: '4.9166667',
      Lng: '-73.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,62',
      municipio: 'San Cristóbal',
      Lat: '9.8833333',
      Lng: '-75.25',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,307',
      municipio: 'Girardot',
      Lat: '4.3030556',
      Lng: '-74.8008333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,312',
      municipio: 'Granada',
      Lat: '3.5386111',
      Lng: '-73.7005556',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,317',
      municipio: 'Guachetá',
      Lat: '5.4166667',
      Lng: '-73.7',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,32',
      municipio: 'Guaduas',
      Lat: '5.25',
      Lng: '-74.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,322',
      municipio: 'Guasca',
      Lat: '4.8333333',
      Lng: '-73.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,324',
      municipio: 'Guataquí',
      Lat: '4.5833333',
      Lng: '-74.8',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,326',
      municipio: 'Guatavita',
      Lat: '4.9666667',
      Lng: '-73.75',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,29',
      municipio: 'Fusagasugá',
      Lat: '5.4166667',
      Lng: '-73.8',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,335',
      municipio: 'Guayabetal',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,339',
      municipio: 'Gutiérrez',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,368',
      municipio: 'Jerusalén',
      Lat: '4.6666667',
      Lng: '-74.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,372',
      municipio: 'Junín',
      Lat: '4.75',
      Lng: '-73.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,377',
      municipio: 'La Calera',
      Lat: '4.75',
      Lng: '-73.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,386',
      municipio: 'La Mesa',
      Lat: '4.6666667',
      Lng: '-74.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,394',
      municipio: 'La Palma',
      Lat: '5.4166667',
      Lng: '-74.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,398',
      municipio: 'La Peña',
      Lat: '5.25',
      Lng: '-74.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,402',
      municipio: 'La Vega',
      Lat: '5',
      Lng: '-74.35',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,407',
      municipio: 'Lenguazaque',
      Lat: '5.3333333',
      Lng: '-73.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,426',
      municipio: 'Macheta',
      Lat: '6.5',
      Lng: '-74.75',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,43',
      municipio: 'Madrid',
      Lat: '4.8333333',
      Lng: '-74.3',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,436',
      municipio: 'Manta',
      Lat: '5.0833333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,438',
      municipio: 'Medina',
      Lat: '4.5',
      Lng: '-73.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,473',
      municipio: 'Mosquera',
      Lat: '2.35',
      Lng: '-78.35',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,483',
      municipio: 'Nariño',
      Lat: '1.5',
      Lng: '-78',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,486',
      municipio: 'Nemocón',
      Lat: '5.1333333',
      Lng: '-73.9',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,488',
      municipio: 'Nilo',
      Lat: '4.3333333',
      Lng: '-74.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,489',
      municipio: 'Nimaima',
      Lat: '5.2',
      Lng: '-74.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,491',
      municipio: 'Nocaima',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,506',
      municipio: 'Venecia',
      Lat: '5.9166667',
      Lng: '-75.75',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,513',
      municipio: 'Pacho',
      Lat: '5.25',
      Lng: '-74.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,518',
      municipio: 'Paime',
      Lat: '5.4166667',
      Lng: '-74.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,524',
      municipio: 'Pandi',
      Lat: '4.25',
      Lng: '-74.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,53',
      municipio: 'Paratebueno',
      Lat: '4.3730556',
      Lng: '-73.2213889',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,535',
      municipio: 'Pasca',
      Lat: '4.3333333',
      Lng: '-74.2833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,572',
      municipio: 'Puerto Salgar',
      Lat: '5.5',
      Lng: '-74.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,58',
      municipio: 'Pulí',
      Lat: '4.75',
      Lng: '-74.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,592',
      municipio: 'Quebradanegra',
      Lat: '5.15',
      Lng: '-74.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,594',
      municipio: 'Quetame',
      Lat: '4.3333333',
      Lng: '-73.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,596',
      municipio: 'Quipile',
      Lat: '4.75',
      Lng: '-74.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,599',
      municipio: 'Apulo',
      Lat: '5.1666667',
      Lng: '-76',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,612',
      municipio: 'Ricaurte',
      Lat: '1.1666667',
      Lng: '-78.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,894',
      municipio: 'Zambrano',
      Lat: '9.75',
      Lng: '-74.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,649',
      municipio: 'San Bernardo',
      Lat: '1.5163889',
      Lng: '-77.0466667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,653',
      municipio: 'San Cayetano',
      Lat: '7.8833333',
      Lng: '-72.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,658',
      municipio: 'San Francisco',
      Lat: '1.1772222',
      Lng: '-76.8830556',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,403',
      municipio: 'La Uvita',
      Lat: '6.3333333',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,899',
      municipio: 'Zipaquirá',
      Lat: '5.0283333',
      Lng: '-74.0058333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,736',
      municipio: 'Sesquilé',
      Lat: '5.0833333',
      Lng: '-73.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,74',
      municipio: 'Sibaté',
      Lat: '4.4913889',
      Lng: '-74.2605556',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,743',
      municipio: 'Silvania',
      Lat: '4.5',
      Lng: '-74.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,745',
      municipio: 'Simijaca',
      Lat: '5.5833333',
      Lng: '-73.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,754',
      municipio: 'Soacha',
      Lat: '4.5872222',
      Lng: '-74.2213889',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,769',
      municipio: 'Subachoque',
      Lat: '5',
      Lng: '-74.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,772',
      municipio: 'Suesca',
      Lat: '5.1666667',
      Lng: '-73.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,777',
      municipio: 'Supatá',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,779',
      municipio: 'Susa',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,781',
      municipio: 'Sutatausa',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,785',
      municipio: 'Tabio',
      Lat: '5',
      Lng: '-74.0833333',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,302',
      municipio: 'Génova',
      Lat: '2.25',
      Lng: '-75.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,793',
      municipio: 'Tausa',
      Lat: '5.25',
      Lng: '-73.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,797',
      municipio: 'Tena',
      Lat: '4.7',
      Lng: '-74.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,799',
      municipio: 'Tenjo',
      Lat: '4.9166667',
      Lng: '-74.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,805',
      municipio: 'Tibacuy',
      Lat: '4.3833333',
      Lng: '-74.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,807',
      municipio: 'Tibirita',
      Lat: '5.1333333',
      Lng: '-73.5333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,815',
      municipio: 'Tocaima',
      Lat: '4.5',
      Lng: '-74.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,817',
      municipio: 'Tocancipá',
      Lat: '5',
      Lng: '-73.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,823',
      municipio: 'Topaipí',
      Lat: '5.4166667',
      Lng: '-74.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,839',
      municipio: 'Ubalá',
      Lat: '4.8333333',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,841',
      municipio: 'Ubaque',
      Lat: '4.5833333',
      Lng: '-74',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,77',
      municipio: 'Suárez',
      Lat: '10.3333333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,845',
      municipio: 'Une',
      Lat: '4.3333333',
      Lng: '-74.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,851',
      municipio: 'Útica',
      Lat: '10.75',
      Lng: '-74.9666667',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,15',
      municipio: 'Castilla la Nueva',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,867',
      municipio: 'Vianí',
      Lat: '4.9166667',
      Lng: '-74.55',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,871',
      municipio: 'Villagómez',
      Lat: '5.2761111',
      Lng: '-74.1988889',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,873',
      municipio: 'Villapinzón',
      Lat: '5.2833333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,875',
      municipio: 'Villeta',
      Lat: '5.0833333',
      Lng: '-74.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,878',
      municipio: 'Viotá',
      Lat: '4.5',
      Lng: '-74.5',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,898',
      municipio: 'Zipacón',
      Lat: '4.8333333',
      Lng: '-74.4166667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,001',
      municipio: 'Quibdó',
      Lat: '5.6947222',
      Lng: '-76.6611111',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,006',
      municipio: 'Acandí',
      Lat: '8.3333333',
      Lng: '-77.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,025',
      municipio: 'Alto Baudo',
      Lat: '2.1666667',
      Lng: '-75.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,05',
      municipio: 'Atrato',
      Lat: '3.3333333',
      Lng: '-75.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,073',
      municipio: 'Bagadó',
      Lat: '5.5',
      Lng: '-76.25',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,075',
      municipio: 'Bahía Solano',
      Lat: '5.5',
      Lng: '-76.25',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,077',
      municipio: 'Bajo Baudó',
      Lat: '6.2191667',
      Lng: '-77.4077778',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,087',
      municipio: 'Belén',
      Lat: '5',
      Lng: '-75.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,099',
      municipio: 'Bojaya',
      Lat: '4.75',
      Lng: '-74.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,81',
      municipio: 'Unión Panamericana',
      Lat: '8.0430556',
      Lng: '-77.0961111',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,57',
      municipio: 'Pueblo Viejo',
      Lat: '5.25',
      Lng: '-76.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,16',
      municipio: 'Cértegui',
      Lat: '10.25',
      Lng: '-74.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,205',
      municipio: 'Condoto',
      Lat: '5.1666667',
      Lng: '-76.5',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,885',
      municipio: 'Villagarzón',
      Lat: '1038',
      Lng: '-76.627',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,269',
      municipio: 'Facatativá',
      Lat: '4.8166667',
      Lng: '-74.3666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,372',
      municipio: 'Juradó',
      Lat: '7',
      Lng: '-77.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,413',
      municipio: 'Lloró',
      Lat: '4.9166667',
      Lng: '-75.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,425',
      municipio: 'Medio Atrato',
      Lat: '6.002152197',
      Lng: '-76.6585975',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,43',
      municipio: 'Medio Baudó',
      Lat: '5.14843104',
      Lng: '-77.0368925',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,45',
      municipio: 'Medio San Juan',
      Lat: '5.14843104',
      Lng: '-77.0368925',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,491',
      municipio: 'Nóvita',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,495',
      municipio: 'Nuquí',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,58',
      municipio: 'Río Iro',
      Lat: '1.1666667',
      Lng: '-78.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,6',
      municipio: 'Río Quito',
      Lat: '7.2191667',
      Lng: '-73.1558333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,615',
      municipio: 'Riosucio',
      Lat: '11.5444444',
      Lng: '-72.9072222',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,58',
      municipio: 'Puerto Libertador',
      Lat: '4.6',
      Lng: '-74.1166667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,745',
      municipio: 'Sipí',
      Lat: '4.5833333',
      Lng: '-76.5',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,8',
      municipio: 'Unguía',
      Lat: '8.0430556',
      Lng: '-77.0961111',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,001',
      municipio: 'Neiva',
      Lat: '2.9305556',
      Lng: '-75.3302778',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,006',
      municipio: 'Acevedo',
      Lat: '1.75',
      Lng: '-75.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,013',
      municipio: 'Agrado',
      Lat: '2.3333333',
      Lng: '-75.75',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,016',
      municipio: 'Aipe',
      Lat: '3.25',
      Lng: '-75.3333333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,02',
      municipio: 'Algeciras',
      Lat: '2.5833333',
      Lng: '-75.25',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,026',
      municipio: 'Altamira',
      Lat: '2.1666667',
      Lng: '-75.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,078',
      municipio: 'Baraya',
      Lat: '3.1666667',
      Lng: '-75',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,132',
      municipio: 'Campoalegre',
      Lat: '2.6666667',
      Lng: '-75.3333333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,206',
      municipio: 'Colombia',
      Lat: '2.5',
      Lng: '-75.75',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,244',
      municipio: 'Elías',
      Lat: '2.0833333',
      Lng: '-76',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,298',
      municipio: 'Garzón',
      Lat: '2.25',
      Lng: '-75.5833333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,306',
      municipio: 'Gigante',
      Lat: '2.5',
      Lng: '-75.5',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,319',
      municipio: 'Guadalupe',
      Lat: '6.3',
      Lng: '-73.3333333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,349',
      municipio: 'Hobo',
      Lat: '2.5833333',
      Lng: '-75.5',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,357',
      municipio: 'Iquira',
      Lat: '0.8302778',
      Lng: '-77.6444444',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,359',
      municipio: 'Isnos',
      Lat: '2.0833333',
      Lng: '-76.25',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,378',
      municipio: 'La Argentina',
      Lat: '2.25',
      Lng: '-76.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,396',
      municipio: 'La Plata',
      Lat: '2.4166667',
      Lng: '-76.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '17',
      codMunicipio: '17,444',
      municipio: 'Marquetalia',
      Lat: '10',
      Lng: '-75.3333333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,483',
      municipio: 'Nátaga',
      Lat: '1.5',
      Lng: '-78',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,503',
      municipio: 'Oporapa',
      Lat: '2.0833333',
      Lng: '-76.0166667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,518',
      municipio: 'Paicol',
      Lat: '2.45',
      Lng: '-75.75',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,524',
      municipio: 'Palermo',
      Lat: '3',
      Lng: '-75.5',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,53',
      municipio: 'Palestina',
      Lat: '1.75',
      Lng: '-76.0666667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,548',
      municipio: 'Pital',
      Lat: '2.3333333',
      Lng: '-75.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,551',
      municipio: 'Pitalito',
      Lat: '1.75',
      Lng: '-76.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,615',
      municipio: 'Rivera',
      Lat: '2.8333333',
      Lng: '-75.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,66',
      municipio: 'Saladoblanco',
      Lat: '2.0144444',
      Lng: '-76.0502778',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,051',
      municipio: 'Arboleda',
      Lat: '4.3',
      Lng: '-74.5833333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,676',
      municipio: 'Santa María',
      Lat: '3',
      Lng: '-75.7',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,77',
      municipio: 'Suaza',
      Lat: '1.9166667',
      Lng: '-75.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,791',
      municipio: 'Tarqui',
      Lat: '2.1666667',
      Lng: '-75.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,797',
      municipio: 'Tesalia',
      Lat: '2.6666667',
      Lng: '-75.75',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,799',
      municipio: 'Tello',
      Lat: '3.0833333',
      Lng: '-75.0833333',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,801',
      municipio: 'Teruel',
      Lat: '2.9166667',
      Lng: '-75.6666667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,807',
      municipio: 'Timaná',
      Lat: '2',
      Lng: '-75.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,872',
      municipio: 'Villavieja',
      Lat: '3.3333333',
      Lng: '-75.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,885',
      municipio: 'Yaguará',
      Lat: '2.6666667',
      Lng: '-75.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,001',
      municipio: 'Riohacha',
      Lat: '11.5444444',
      Lng: '-72.9072222',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,035',
      municipio: 'Albania',
      Lat: '1.4744444',
      Lng: '-77.0836111',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,078',
      municipio: 'Barrancas',
      Lat: '11',
      Lng: '-72.75',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,09',
      municipio: 'Dibula',
      Lat: '3.6602778',
      Lng: '-76.6927778',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,098',
      municipio: 'Distracción',
      Lat: '10.8977778',
      Lng: '-72.8869444',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,11',
      municipio: 'El Molino',
      Lat: '10.6533333',
      Lng: '-72.9241667',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,279',
      municipio: 'Fonseca',
      Lat: '10.8333333',
      Lng: '-72.8333333',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,378',
      municipio: 'Hatonuevo',
      Lat: '11.0694444',
      Lng: '-72.7669444',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,43',
      municipio: 'Maicao',
      Lat: '11.3841667',
      Lng: '-72.2441667',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,56',
      municipio: 'Manaure',
      Lat: '11.7791667',
      Lng: '-72.4494444',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,847',
      municipio: 'Uribia',
      Lat: '11.9166667',
      Lng: '-72',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,855',
      municipio: 'Urumita',
      Lat: '10.5666667',
      Lng: '-73.0166667',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,874',
      municipio: 'Villanueva',
      Lat: '5.2833333',
      Lng: '-71.9666667',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,001',
      municipio: 'Santa Marta',
      Lat: '3',
      Lng: '-75.7',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,03',
      municipio: 'Algarrobo',
      Lat: '10.1869444',
      Lng: '-74.5752778',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,053',
      municipio: 'Aracataca',
      Lat: '10.5833333',
      Lng: '-74',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,058',
      municipio: 'Ariguaní',
      Lat: '4.7',
      Lng: '-76.1333333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,161',
      municipio: 'Cerro San Antonio',
      Lat: '10.25',
      Lng: '-74.8333333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,17',
      municipio: 'Chivolo',
      Lat: '10.0280556',
      Lng: '-74.6436111',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,205',
      municipio: 'Concordia',
      Lat: '9.8402778',
      Lng: '-74.4447222',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,245',
      municipio: 'El Banco',
      Lat: '9.0455556',
      Lng: '-73.9747222',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,258',
      municipio: 'El Piñon',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,268',
      municipio: 'El Retén',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,288',
      municipio: 'Fundación',
      Lat: '10.4166667',
      Lng: '-73.9166667',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,318',
      municipio: 'Guamal',
      Lat: '3.9166667',
      Lng: '-74',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,46',
      municipio: 'Nueva Granada',
      Lat: '2.65',
      Lng: '-75.8',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,541',
      municipio: 'Pedraza',
      Lat: '10.1666667',
      Lng: '-74.75',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,551',
      municipio: 'Pivijay',
      Lat: '10.4166667',
      Lng: '-74.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,555',
      municipio: 'Plato',
      Lat: '9.8333333',
      Lng: '-74.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,605',
      municipio: 'Remolino',
      Lat: '10.6666667',
      Lng: '-74.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,675',
      municipio: 'Salamina',
      Lat: '10.5',
      Lng: '-74.7',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,703',
      municipio: 'San Zenón',
      Lat: '9.3333333',
      Lng: '-74.3',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,707',
      municipio: 'Santa Ana',
      Lat: '9.3166667',
      Lng: '-74.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,745',
      municipio: 'Sitionuevo',
      Lat: '10.9166667',
      Lng: '-74.5833333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,798',
      municipio: 'Tenerife',
      Lat: '10',
      Lng: '-74.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,96',
      municipio: 'Zapayán',
      Lat: '6.8333333',
      Lng: '-73.25',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,98',
      municipio: 'Zona Bananera',
      Lat: '5.0283333',
      Lng: '-74.0058333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,001',
      municipio: 'Villavicencio',
      Lat: '4.1533333',
      Lng: '-73.635',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,006',
      municipio: 'Acacias',
      Lat: '6.6666667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,124',
      municipio: 'Cabuyaro',
      Lat: '4.2902778',
      Lng: '-72.7905556',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,223',
      municipio: 'Cubarral',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,226',
      municipio: 'Cumaral',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,245',
      municipio: 'El Calvario',
      Lat: '4.4166667',
      Lng: '-73.6666667',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,251',
      municipio: 'El Castillo',
      Lat: '3.5680556',
      Lng: '-73.7838889',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,27',
      municipio: 'El Dorado',
      Lat: '2.7791667',
      Lng: '-72.8719444',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,109',
      municipio: 'Buenaventura',
      Lat: '3.8933333',
      Lng: '-77.0697222',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,313',
      municipio: 'Granada',
      Lat: '3.5386111',
      Lng: '-73.7005556',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,318',
      municipio: 'Guamal',
      Lat: '3.9166667',
      Lng: '-74',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,325',
      municipio: 'Mapiripán',
      Lat: '2.8911111',
      Lng: '-72.1322222',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,33',
      municipio: 'Mesetas',
      Lat: '3.3780556',
      Lng: '-74.0447222',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,35',
      municipio: 'La Macarena',
      Lat: '2.8536111',
      Lng: '-73.9436111',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,37',
      municipio: 'Uribe',
      Lat: '3254',
      Lng: '-74.358',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,4',
      municipio: 'Lejanías',
      Lat: '3.5136111',
      Lng: '-74.0516667',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,45',
      municipio: 'Puerto Concordia',
      Lat: '2.6794444',
      Lng: '-72.7608333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,568',
      municipio: 'Puerto Gaitán',
      Lat: '4.3141667',
      Lng: '-72.0825',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,573',
      municipio: 'Puerto López',
      Lat: '4.0833333',
      Lng: '-72.9666667',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,577',
      municipio: 'Puerto Lleras',
      Lat: '3',
      Lng: '-72.5',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,59',
      municipio: 'Puerto Rico',
      Lat: '2.9383333',
      Lng: '-73.2083333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,606',
      municipio: 'Restrepo',
      Lat: '3.7833333',
      Lng: '-76.5',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,189',
      municipio: 'Ciénaga',
      Lat: '9.4166667',
      Lng: '-74.7333333',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,56',
      municipio: 'Ponedera',
      Lat: '10.6436111',
      Lng: '-74.7544444',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,686',
      municipio: 'San Juanito',
      Lat: '4.4586111',
      Lng: '-73.6730556',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,689',
      municipio: 'San Martín',
      Lat: '3.7',
      Lng: '-73.7',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,711',
      municipio: 'Vista Hermosa',
      Lat: '3.0863889',
      Lng: '-73.7513889',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,001',
      municipio: 'Pasto',
      Lat: '4.3333333',
      Lng: '-74.2833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,019',
      municipio: 'Albán',
      Lat: '4.9166667',
      Lng: '-74.45',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,022',
      municipio: 'Aldana',
      Lat: '0.9166667',
      Lng: '-77.6833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,036',
      municipio: 'Ancuyá',
      Lat: '1.25',
      Lng: '-77.5',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,832',
      municipio: 'Tununguá',
      Lat: '5.7333333',
      Lng: '-73.9333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,079',
      municipio: 'Barbacoas',
      Lat: '1.5833333',
      Lng: '-78',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,476',
      municipio: 'Motavita',
      Lat: '8.25',
      Lng: '-76.05',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,675',
      municipio: 'San Bernardo del Viento',
      Lat: '9.25',
      Lng: '-76',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,203',
      municipio: 'Colón',
      Lat: '2.5',
      Lng: '-75.75',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,207',
      municipio: 'Consaca',
      Lat: '1.20840649727186',
      Lng: '-77.4632263183594',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,21',
      municipio: 'Contadero',
      Lat: '0.9166667',
      Lng: '-77.5333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,215',
      municipio: 'Córdoba',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,224',
      municipio: 'Cuaspud',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,227',
      municipio: 'Cumbal',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,233',
      municipio: 'Cumbitara',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,25',
      municipio: 'El Charco',
      Lat: '2.3333333',
      Lng: '-78.35',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,254',
      municipio: 'El Peñol',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,256',
      municipio: 'El Rosario',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,361',
      municipio: 'Istmina',
      Lat: '5.1588889',
      Lng: '-76.6916667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,26',
      municipio: 'El Tambo',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,287',
      municipio: 'Funes',
      Lat: '0.95',
      Lng: '-77.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,317',
      municipio: 'Guachucal',
      Lat: '1',
      Lng: '-77.75',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,32',
      municipio: 'Guaitarilla',
      Lat: '1.15',
      Lng: '-77.5',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,323',
      municipio: 'Gualmatán',
      Lat: '0.8333333',
      Lng: '-77.5833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,352',
      municipio: 'Iles',
      Lat: '0.95',
      Lng: '-77.5333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,354',
      municipio: 'Imués',
      Lat: '0.95',
      Lng: '-77.5333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,356',
      municipio: 'Ipiales',
      Lat: '0.8302778',
      Lng: '-77.6444444',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,378',
      municipio: 'La Cruz',
      Lat: '1.55',
      Lng: '-76.8833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,381',
      municipio: 'La Florida',
      Lat: '1.25',
      Lng: '-77.4166667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,385',
      municipio: 'La Llanada',
      Lat: '1.4777778',
      Lng: '-77.5838889',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,39',
      municipio: 'La Tola',
      Lat: '2.4108333',
      Lng: '-78.2427778',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,399',
      municipio: 'La Unión',
      Lat: '4.5358333',
      Lng: '-76.1066667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,405',
      municipio: 'Leiva',
      Lat: '1.9375',
      Lng: '-77.3080556',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,411',
      municipio: 'Linares',
      Lat: '4.9166667',
      Lng: '-75.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,418',
      municipio: 'Los Andes',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,427',
      municipio: 'Magüí',
      Lat: '1.9166667',
      Lng: '-77.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,435',
      municipio: 'Mallama',
      Lat: '10.8588889',
      Lng: '-74.7730556',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,473',
      municipio: 'Mosquera',
      Lat: '2.35',
      Lng: '-78.35',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,48',
      municipio: 'Nariño',
      Lat: '1.5',
      Lng: '-78',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,49',
      municipio: 'Olaya Herrera',
      Lat: '1.2666667',
      Lng: '-77.4833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,506',
      municipio: 'Ospina',
      Lat: '1.0333333',
      Lng: '-77.55',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,52',
      municipio: 'Francisco Pizarro',
      Lat: '2.1019444',
      Lng: '-78.7216667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,54',
      municipio: 'Policarpa',
      Lat: '1.6316667',
      Lng: '-77.4616667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,56',
      municipio: 'Potosí',
      Lat: '0.75',
      Lng: '-77.4166667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,565',
      municipio: 'Providencia',
      Lat: '12.5',
      Lng: '-81.75',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,573',
      municipio: 'Puerres',
      Lat: '0.9166667',
      Lng: '-77.25',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,585',
      municipio: 'Pupiales',
      Lat: '0.9166667',
      Lng: '-77.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,612',
      municipio: 'Ricaurte',
      Lat: '1.1666667',
      Lng: '-78.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,621',
      municipio: 'Roberto Payán',
      Lat: '2.8333333',
      Lng: '-75.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,678',
      municipio: 'Samaniego',
      Lat: '5.5833333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,683',
      municipio: 'Sandoná',
      Lat: '1.25',
      Lng: '-77.45',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,685',
      municipio: 'San Bernardo',
      Lat: '1.5163889',
      Lng: '-77.0466667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,687',
      municipio: 'San Lorenzo',
      Lat: '1.5',
      Lng: '-77.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,693',
      municipio: 'San Pablo',
      Lat: '1.6666667',
      Lng: '-76.9166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,086',
      municipio: 'Belmira',
      Lat: '1.4166667',
      Lng: '-75.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,189',
      municipio: 'Ciénega',
      Lat: '9.4166667',
      Lng: '-74.7333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,696',
      municipio: 'Santa Bárbara',
      Lat: '6.9927778',
      Lng: '-72.9102778',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,72',
      municipio: 'Sapuyes',
      Lat: '1.0333333',
      Lng: '-77.6',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,786',
      municipio: 'Taminango',
      Lat: '1.5833333',
      Lng: '-77.25',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,788',
      municipio: 'Tangua',
      Lat: '1.0833333',
      Lng: '-77.3',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,699',
      municipio: 'Santacruz',
      Lat: '5.75',
      Lng: '-73.5833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,838',
      municipio: 'Túquerres',
      Lat: '1.1666667',
      Lng: '-77.5833333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,885',
      municipio: 'Yacuanquer',
      Lat: '1.0833333',
      Lng: '-77.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,575',
      municipio: 'Puerto Wilches',
      Lat: '7.5833333',
      Lng: '-73.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,573',
      municipio: 'Puerto Parra',
      Lat: '6.6516667',
      Lng: '-74.0608333',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,001',
      municipio: 'Armenia',
      Lat: '4.5338889',
      Lng: '-75.6811111',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,111',
      municipio: 'Buenavista',
      Lat: '9.3222222',
      Lng: '-74.9772222',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,19',
      municipio: 'Circasia',
      Lat: '4.6166667',
      Lng: '-75.6333333',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,212',
      municipio: 'Córdoba',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,272',
      municipio: 'Filandia',
      Lat: '4.6666667',
      Lng: '-75.6333333',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,401',
      municipio: 'La Tebaida',
      Lat: '4.45',
      Lng: '-75.8',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,47',
      municipio: 'Montenegro',
      Lat: '4.5',
      Lng: '-75.8',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,548',
      municipio: 'Pijao',
      Lat: '4.3333333',
      Lng: '-75.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,594',
      municipio: 'Quimbaya',
      Lat: '4.6333333',
      Lng: '-75.75',
    },
    {
      codRegion: '1',
      codDepartamento: '63',
      codMunicipio: '63,69',
      municipio: 'Salento',
      Lat: '4.6666667',
      Lng: '-75.5',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,001',
      municipio: 'Pereira',
      Lat: '4.8133333',
      Lng: '-75.6961111',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,045',
      municipio: 'Apía',
      Lat: '5.1666667',
      Lng: '-76',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,075',
      municipio: 'Balboa',
      Lat: '4.9166667',
      Lng: '-75.95',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,17',
      municipio: 'Dosquebradas',
      Lat: '4.8347222',
      Lng: '-75.6725',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,318',
      municipio: 'Guática',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,383',
      municipio: 'La Celia',
      Lat: '4.9666667',
      Lng: '-76',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,4',
      municipio: 'La Virginia',
      Lat: '4.9166667',
      Lng: '-75.8333333',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,44',
      municipio: 'Marsella',
      Lat: '10',
      Lng: '-75.3333333',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,456',
      municipio: 'Mistrató',
      Lat: '5.4166667',
      Lng: '-76',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,572',
      municipio: 'Pueblo Rico',
      Lat: '5.25',
      Lng: '-76.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,594',
      municipio: 'Quinchía',
      Lat: '5.3333333',
      Lng: '-75.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,687',
      municipio: 'Santuario',
      Lat: '5.0833333',
      Lng: '-76',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,001',
      municipio: 'Bucaramanga',
      Lat: '7.1297222',
      Lng: '-73.1258333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,013',
      municipio: 'Aguada',
      Lat: '6.25',
      Lng: '-73.4666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,02',
      municipio: 'Albania',
      Lat: '1.4744444',
      Lng: '-77.0836111',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,051',
      municipio: 'Aratoca',
      Lat: '6.8333333',
      Lng: '-72.9666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,077',
      municipio: 'Barbosa',
      Lat: '6',
      Lng: '-73.55',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,079',
      municipio: 'Barichara',
      Lat: '6.75',
      Lng: '-73.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,081',
      municipio: 'Barrancabermeja',
      Lat: '7.0652778',
      Lng: '-73.8547222',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,092',
      municipio: 'Betulia',
      Lat: '7.0833333',
      Lng: '-73.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,101',
      municipio: 'Bolívar',
      Lat: '4.3333333',
      Lng: '-76.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,121',
      municipio: 'Cabrera',
      Lat: '6.6333333',
      Lng: '-73.2',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,132',
      municipio: 'California',
      Lat: '7.4166667',
      Lng: '-72.85',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,152',
      municipio: 'Carcasí',
      Lat: '6.8333333',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,16',
      municipio: 'Cepitá',
      Lat: '6.8333333',
      Lng: '-72.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,162',
      municipio: 'Cerrito',
      Lat: '7',
      Lng: '-72.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,167',
      municipio: 'Charalá',
      Lat: '6.25',
      Lng: '-73.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,169',
      municipio: 'Charta',
      Lat: '7.3',
      Lng: '-72.9666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,179',
      municipio: 'Chipatá',
      Lat: '6.1333333',
      Lng: '-73.55',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,19',
      municipio: 'Cimitarra',
      Lat: '6.3161111',
      Lng: '-73.9505556',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,207',
      municipio: 'Concepción',
      Lat: '6.9166667',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,209',
      municipio: 'Confines',
      Lat: '6.4166667',
      Lng: '-73.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,211',
      municipio: 'Contratación',
      Lat: '6.3333333',
      Lng: '-73.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,217',
      municipio: 'Coromoro',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,229',
      municipio: 'Curití',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,245',
      municipio: 'El Guacamayo',
      Lat: '6.3333333',
      Lng: '-73.4666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,255',
      municipio: 'El Playón',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,264',
      municipio: 'Encino',
      Lat: '6.1666667',
      Lng: '-73',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,266',
      municipio: 'Enciso',
      Lat: '6.75',
      Lng: '-72.6333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,271',
      municipio: 'Florián',
      Lat: '5.8047222',
      Lng: '-73.9741667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,276',
      municipio: 'Floridablanca',
      Lat: '5.8047222',
      Lng: '-73.9741667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,296',
      municipio: 'Galán',
      Lat: '6.7',
      Lng: '-73.3',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,298',
      municipio: 'Gambita',
      Lat: '8.3333333',
      Lng: '-73.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,307',
      municipio: 'Girón',
      Lat: '7.0708333',
      Lng: '-73.1730556',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,318',
      municipio: 'Guaca',
      Lat: '7',
      Lng: '-72.7666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,32',
      municipio: 'Guadalupe',
      Lat: '6.3',
      Lng: '-73.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,322',
      municipio: 'Guapotá',
      Lat: '2.4166667',
      Lng: '-77.6',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,324',
      municipio: 'Guavatá',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,327',
      municipio: 'Güepsa',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,368',
      municipio: 'Jesús María',
      Lat: '5.9166667',
      Lng: '-73.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,37',
      municipio: 'Jordán',
      Lat: '6.75',
      Lng: '-73.0666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,377',
      municipio: 'La Belleza',
      Lat: '5.8613889',
      Lng: '-73.9683333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,385',
      municipio: 'Landázuri',
      Lat: '6.2238889',
      Lng: '-73.8127778',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,397',
      municipio: 'La Paz',
      Lat: '6.25',
      Lng: '-73.4666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,406',
      municipio: 'Lebríja',
      Lat: '7.4166667',
      Lng: '-73.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,418',
      municipio: 'Los Santos',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,425',
      municipio: 'Macaravita',
      Lat: '6.5833333',
      Lng: '-72.5333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,432',
      municipio: 'Málaga',
      Lat: '8.5',
      Lng: '-74.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,444',
      municipio: 'Matanza',
      Lat: '7.4166667',
      Lng: '-73.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,464',
      municipio: 'Mogotes',
      Lat: '6.5833333',
      Lng: '-72.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,468',
      municipio: 'Molagavita',
      Lat: '6.75',
      Lng: '-72.75',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,498',
      municipio: 'Ocamonte',
      Lat: '6.4166667',
      Lng: '-73.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,5',
      municipio: 'Oiba',
      Lat: '6.3',
      Lng: '-73.25',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,502',
      municipio: 'Onzaga',
      Lat: '6.3333333',
      Lng: '-72.75',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,522',
      municipio: 'Palmar',
      Lat: '6.5833333',
      Lng: '-73.25',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,533',
      municipio: 'Páramo',
      Lat: '6.5',
      Lng: '-72.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,547',
      municipio: 'Piedecuesta',
      Lat: '5.8383333',
      Lng: '-74.0366667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,549',
      municipio: 'Pinchote',
      Lat: '6.5833333',
      Lng: '-73.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,572',
      municipio: 'Puente Nacional',
      Lat: '5.9166667',
      Lng: '-73.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,615',
      municipio: 'Rionegro',
      Lat: '11.5444444',
      Lng: '-72.9072222',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,669',
      municipio: 'San Andrés',
      Lat: '6.8333333',
      Lng: '-72.75',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,679',
      municipio: 'San Gil',
      Lat: '1.1772222',
      Lng: '-76.8830556',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,682',
      municipio: 'San Joaquín',
      Lat: '6.4333333',
      Lng: '-72.8666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,686',
      municipio: 'San Miguel',
      Lat: '0.3394444',
      Lng: '-76.8830556',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,705',
      municipio: 'Santa Bárbara',
      Lat: '6.9927778',
      Lng: '-72.9102778',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,745',
      municipio: 'Simacota',
      Lat: '6.4472222',
      Lng: '-73.3413889',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,755',
      municipio: 'Socorro',
      Lat: '6.5333333',
      Lng: '-73.2',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,77',
      municipio: 'Suaita',
      Lat: '6.1666667',
      Lng: '-73.3',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,773',
      municipio: 'Sucre',
      Lat: '9',
      Lng: '-75',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,78',
      municipio: 'Suratá',
      Lat: '5.6666667',
      Lng: '-73.5',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,82',
      municipio: 'Tona',
      Lat: '7.25',
      Lng: '-72.9',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,861',
      municipio: 'Vélez',
      Lat: '6.7730556',
      Lng: '-74.8016667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,867',
      municipio: 'Vetas',
      Lat: '7.3333333',
      Lng: '-72.8666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,872',
      municipio: 'Villanueva',
      Lat: '5.2833333',
      Lng: '-71.9666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,895',
      municipio: 'Zapatoca',
      Lat: '6.8333333',
      Lng: '-73.25',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,001',
      municipio: 'Sincelejo',
      Lat: '9.1666667',
      Lng: '-75',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,11',
      municipio: 'Buenavista',
      Lat: '9.3222222',
      Lng: '-74.9772222',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,124',
      municipio: 'Caimito',
      Lat: '8.8333333',
      Lng: '-75.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,204',
      municipio: 'Coloso',
      Lat: '2.5',
      Lng: '-75.75',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,221',
      municipio: 'Coveñas',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,23',
      municipio: 'Chalán',
      Lat: '9.5491667',
      Lng: '-75.3161111',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,233',
      municipio: 'El Roble',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,235',
      municipio: 'Galeras',
      Lat: '6.7',
      Lng: '-73.3',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,265',
      municipio: 'Guaranda',
      Lat: '8.4697222',
      Lng: '-75.5383333',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,4',
      municipio: 'La Unión',
      Lat: '4.5358333',
      Lng: '-76.1066667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,418',
      municipio: 'Los Palmitos',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,429',
      municipio: 'Majagual',
      Lat: '8.5',
      Lng: '-74.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,473',
      municipio: 'Morroa',
      Lat: '9.4166667',
      Lng: '-75.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,508',
      municipio: 'Ovejas',
      Lat: '9.5',
      Lng: '-75.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,523',
      municipio: 'Palmito',
      Lat: '9.3333333',
      Lng: '-75.55',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,678',
      municipio: 'San Benito Abad',
      Lat: '8.75',
      Lng: '-74.9166667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,708',
      municipio: 'San Marcos',
      Lat: '8.5833333',
      Lng: '-75.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,713',
      municipio: 'San Onofre',
      Lat: '9.8333333',
      Lng: '-75.5',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,717',
      municipio: 'San Pedro',
      Lat: '4',
      Lng: '-76.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,771',
      municipio: 'Sucre',
      Lat: '9',
      Lng: '-75',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,823',
      municipio: 'Tolú Viejo',
      Lat: '9.452848597',
      Lng: '-75.43913555',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,024',
      municipio: 'Alpujarra',
      Lat: '3.4166667',
      Lng: '-74.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,026',
      municipio: 'Alvarado',
      Lat: '4.5833333',
      Lng: '-75',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,03',
      municipio: 'Ambalema',
      Lat: '4.8333333',
      Lng: '-74.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,055',
      municipio: 'Armero',
      Lat: '4.5338889',
      Lng: '-75.6811111',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,067',
      municipio: 'Ataco',
      Lat: '3.3333333',
      Lng: '-75.6666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,124',
      municipio: 'Cajamarca',
      Lat: '4.4166667',
      Lng: '-75.5',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,168',
      municipio: 'Chaparral',
      Lat: '3.75',
      Lng: '-75.5833333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,2',
      municipio: 'Coello',
      Lat: '4.3333333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,217',
      municipio: 'Coyaima',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,226',
      municipio: 'Cunday',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,236',
      municipio: 'Dolores',
      Lat: '3.6666667',
      Lng: '-74.75',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,268',
      municipio: 'Espinal',
      Lat: '4.2',
      Lng: '-74.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,27',
      municipio: 'Falan',
      Lat: '5.1333333',
      Lng: '-75',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,275',
      municipio: 'Flandes',
      Lat: '4.25',
      Lng: '-74.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,283',
      municipio: 'Fresno',
      Lat: '5.1666667',
      Lng: '-75.0833333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,319',
      municipio: 'Guamo',
      Lat: '4.0833333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,347',
      municipio: 'Herveo',
      Lat: '5.0833333',
      Lng: '-75.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,349',
      municipio: 'Honda',
      Lat: '5.25',
      Lng: '-74.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,352',
      municipio: 'Icononzo',
      Lat: '4.1666667',
      Lng: '-74.55',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,443',
      municipio: 'Mariquita',
      Lat: '5.25',
      Lng: '-74.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,449',
      municipio: 'Melgar',
      Lat: '4.25',
      Lng: '-74.5833333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,461',
      municipio: 'Murillo',
      Lat: '4.8769444',
      Lng: '-75.1738889',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,483',
      municipio: 'Natagaima',
      Lat: '3.5833333',
      Lng: '-75.0833333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,504',
      municipio: 'Ortega',
      Lat: '3.9166667',
      Lng: '-75.25',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,52',
      municipio: 'Palocabildo',
      Lat: '5.1333333',
      Lng: '-75.0333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,547',
      municipio: 'Piedras',
      Lat: '4.5',
      Lng: '-74.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,555',
      municipio: 'Planadas',
      Lat: '3.25',
      Lng: '-75.75',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,563',
      municipio: 'Prado',
      Lat: '3.75',
      Lng: '-74.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,585',
      municipio: 'Purificación',
      Lat: '6.5',
      Lng: '-73.1333333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,616',
      municipio: 'Rio Blanco',
      Lat: '1.1666667',
      Lng: '-78.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,622',
      municipio: 'Roncesvalles',
      Lat: '4.0833333',
      Lng: '-75.5833333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,624',
      municipio: 'Rovira',
      Lat: '7.4166667',
      Lng: '-77.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,671',
      municipio: 'Saldaña',
      Lat: '3.9347222',
      Lng: '-75.0202778',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,686',
      municipio: 'Santa Isabel',
      Lat: '4.75',
      Lng: '-75.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,861',
      municipio: 'Venadillo',
      Lat: '4.75',
      Lng: '-74.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,87',
      municipio: 'Villahermosa',
      Lat: '5',
      Lng: '-75.1666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,873',
      municipio: 'Villarrica',
      Lat: '4',
      Lng: '-74.5833333',
    },
    {
      codRegion: '4',
      codDepartamento: '81',
      codMunicipio: '81,065',
      municipio: 'Arauquita',
      Lat: '6.8219444',
      Lng: '-71.3236111',
    },
    {
      codRegion: '4',
      codDepartamento: '81',
      codMunicipio: '81,22',
      municipio: 'Cravo Norte',
      Lat: '0.7833333',
      Lng: '-77.3333333',
    },
    {
      codRegion: '4',
      codDepartamento: '81',
      codMunicipio: '81,3',
      municipio: 'Fortul',
      Lat: '10.8333333',
      Lng: '-72.8333333',
    },
    {
      codRegion: '4',
      codDepartamento: '81',
      codMunicipio: '81,591',
      municipio: 'Puerto Rondón',
      Lat: '6.2780556',
      Lng: '-71.1',
    },
    {
      codRegion: '4',
      codDepartamento: '81',
      codMunicipio: '81,736',
      municipio: 'Saravena',
      Lat: '6.9205556',
      Lng: '-71.8533333',
    },
    {
      codRegion: '4',
      codDepartamento: '81',
      codMunicipio: '81,794',
      municipio: 'Tame',
      Lat: '6.4641667',
      Lng: '-71.7288889',
    },
    {
      codRegion: '4',
      codDepartamento: '81',
      codMunicipio: '81,001',
      municipio: 'Arauca',
      Lat: '7.0902778',
      Lng: '-70.7616667',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,001',
      municipio: 'Yopal',
      Lat: '5.3394444',
      Lng: '-72.3941667',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,01',
      municipio: 'Aguazul',
      Lat: '5.1730556',
      Lng: '-72.5547222',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,015',
      municipio: 'Chámeza',
      Lat: '9.5491667',
      Lng: '-75.3161111',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,125',
      municipio: 'Hato Corozal',
      Lat: '6.1555556',
      Lng: '-71.7655556',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,136',
      municipio: 'La Salina',
      Lat: '6.1852778',
      Lng: '-72.3444444',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,162',
      municipio: 'Monterrey',
      Lat: '4.9166667',
      Lng: '-72.8333333',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,263',
      municipio: 'Pore',
      Lat: '5.6708333',
      Lng: '-71.93',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,279',
      municipio: 'Recetor',
      Lat: '5.3333333',
      Lng: '-72.75',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,3',
      municipio: 'Sabanalarga',
      Lat: '4.8536111',
      Lng: '-73.0430556',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,315',
      municipio: 'Sácama',
      Lat: '5.75',
      Lng: '-73.7',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,41',
      municipio: 'Tauramena',
      Lat: '5.0186111',
      Lng: '-72.7552778',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,43',
      municipio: 'Trinidad',
      Lat: '5.4305556',
      Lng: '-71.6311111',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,44',
      municipio: 'Villanueva',
      Lat: '5.2833333',
      Lng: '-71.9666667',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,001',
      municipio: 'Mocoa',
      Lat: '1.1488889',
      Lng: '-76.6477778',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,219',
      municipio: 'Colón',
      Lat: '2.5',
      Lng: '-75.75',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,32',
      municipio: 'Orito',
      Lat: '0.6966667',
      Lng: '-76.8747222',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,569',
      municipio: 'Puerto Caicedo',
      Lat: '0.6838889',
      Lng: '-76.5858333',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,571',
      municipio: 'Puerto Guzmán',
      Lat: '0.9702778',
      Lng: '-76.5858333',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,573',
      municipio: 'Leguízamo',
      Lat: '7.4166667',
      Lng: '-73.4166667',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,749',
      municipio: 'Sibundoy',
      Lat: '1.1833333',
      Lng: '-76.9166667',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,755',
      municipio: 'San Francisco',
      Lat: '1.1772222',
      Lng: '-76.8830556',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,757',
      municipio: 'San Miguel',
      Lat: '0.3394444',
      Lng: '-76.8830556',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,76',
      municipio: 'Santiago',
      Lat: '1.1511111',
      Lng: '-77.0075',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,001',
      municipio: 'Leticia',
      Lat: '-4.2152778',
      Lng: '-69.9405556',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,263',
      municipio: 'El Encanto',
      Lat: '4.5',
      Lng: '-76.25',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,405',
      municipio: 'La Chorrera',
      Lat: '4.9666667',
      Lng: '-76',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,407',
      municipio: 'La Pedrera',
      Lat: '10.1666667',
      Lng: '-73.25',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,43',
      municipio: 'La Victoria',
      Lat: '4.5238889',
      Lng: '-76.0411111',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,536',
      municipio: 'Puerto Arica',
      Lat: '0.9166667',
      Lng: '-77.25',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,54',
      municipio: 'Puerto Nariño',
      Lat: '-3.7702778',
      Lng: '-70.3830556',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,669',
      municipio: 'Puerto Santander',
      Lat: '8.3636111',
      Lng: '-72.4075',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,798',
      municipio: 'Tarapacá',
      Lat: '0.4938889',
      Lng: '-69.6669444',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,001',
      municipio: 'Inírida',
      Lat: '3.8652778',
      Lng: '-67.9238889',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,343',
      municipio: 'Barranco Minas',
      Lat: '8.8333333',
      Lng: '-74.1666667',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,663',
      municipio: 'Mapiripana',
      Lat: '2.8911111',
      Lng: '-72.1322222',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,883',
      municipio: 'San Felipe',
      Lat: '10.3983333',
      Lng: '-75.1511111',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,884',
      municipio: 'Puerto Colombia',
      Lat: '11.0166667',
      Lng: '-74.8833333',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,885',
      municipio: 'La Guadalupe',
      Lat: '8.5833333',
      Lng: '-73.5833333',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,886',
      municipio: 'Cacahual',
      Lat: '4.2902778',
      Lng: '-72.7905556',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,887',
      municipio: 'Pana Pana',
      Lat: '7.5',
      Lng: '-72.5833333',
    },
    {
      codRegion: '4',
      codDepartamento: '94',
      codMunicipio: '94,888',
      municipio: 'Morichal',
      Lat: '1.4875',
      Lng: '-75.725',
    },
    {
      codRegion: '4',
      codDepartamento: '97',
      codMunicipio: '97,001',
      municipio: 'Mitú',
      Lat: '1.1983333',
      Lng: '-70.1733333',
    },
    {
      codRegion: '4',
      codDepartamento: '97',
      codMunicipio: '97,161',
      municipio: 'Caruru',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '4',
      codDepartamento: '97',
      codMunicipio: '97,511',
      municipio: 'Pacoa',
      Lat: '5.25',
      Lng: '-74.1666667',
    },
    {
      codRegion: '4',
      codDepartamento: '97',
      codMunicipio: '97,666',
      municipio: 'Taraira',
      Lat: '0.4938889',
      Lng: '-69.6669444',
    },
    {
      codRegion: '4',
      codDepartamento: '97',
      codMunicipio: '97,777',
      municipio: 'Papunaua',
      Lat: '6.5',
      Lng: '-72.4166667',
    },
    {
      codRegion: '4',
      codDepartamento: '99',
      codMunicipio: '99,001',
      municipio: 'Puerto Carreño',
      Lat: '6.1877778',
      Lng: '-67.4730556',
    },
    {
      codRegion: '4',
      codDepartamento: '99',
      codMunicipio: '99,524',
      municipio: 'La Primavera',
      Lat: '5.4905556',
      Lng: '-70.4091667',
    },
    {
      codRegion: '4',
      codDepartamento: '99',
      codMunicipio: '99,624',
      municipio: 'Santa Rosalía',
      Lat: '5.1261111',
      Lng: '-70.8758333',
    },
    {
      codRegion: '4',
      codDepartamento: '99',
      codMunicipio: '99,773',
      municipio: 'Cumaribo',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,61',
      municipio: 'San José del Fragua',
      Lat: '1.3611111',
      Lng: '-75.9883333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,11',
      municipio: 'Barranca de Upía',
      Lat: '4.5755556',
      Lng: '-72.9611111',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,524',
      municipio: 'Palmas del Socorro',
      Lat: '6.45',
      Lng: '-73.25',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,662',
      municipio: 'San Juan de Río Seco',
      Lat: '4.9166667',
      Lng: '-74.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,372',
      municipio: 'Juan de Acosta',
      Lat: '10.8333333',
      Lng: '-75.0333333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,287',
      municipio: 'Fuente de Oro',
      Lat: '3.4594444',
      Lng: '-73.6127778',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,325',
      municipio: 'San Luis de Gaceno',
      Lat: '6',
      Lng: '-74.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,25',
      municipio: 'El Litoral del San Juan',
      Lat: '10.0833333',
      Lng: '-74.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,843',
      municipio: 'Villa de San Diego de Ubate',
      Lat: '7.9169444',
      Lng: '-72.9763889',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,074',
      municipio: 'Barranco de Loba',
      Lat: '8.8333333',
      Lng: '-74.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,816',
      municipio: 'Togüí',
      Lat: '5.95',
      Lng: '-73.4166667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,688',
      municipio: 'Santa Rosa del Sur',
      Lat: '7.9644444',
      Lng: '-74.0544444',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,135',
      municipio: 'El Cantón del San Pablo',
      Lat: '4.4166667',
      Lng: '-73.6666667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,407',
      municipio: 'Villa de Leyva',
      Lat: '7.9169444',
      Lng: '-72.9763889',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,692',
      municipio: 'San Sebastián de Buenavista',
      Lat: '9.3333333',
      Lng: '-74.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,537',
      municipio: 'Paz de Río',
      Lat: '6.0833333',
      Lng: '-72.75',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,3',
      municipio: 'Hatillo de Loba',
      Lat: '8.9586111',
      Lng: '-74.0808333',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,66',
      municipio: 'Sabanas de San Angel',
      Lat: '4.8536111',
      Lng: '-73.0430556',
    },
    {
      codRegion: '4',
      codDepartamento: '95',
      codMunicipio: '95,015',
      municipio: 'Calamar',
      Lat: '1.9597222',
      Lng: '-72.6538889',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,614',
      municipio: 'Río de Oro',
      Lat: '1.1666667',
      Lng: '-78.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,665',
      municipio: 'San Pedro de Uraba',
      Lat: '1.5613889',
      Lng: '-77.1180556',
    },
    {
      codRegion: '4',
      codDepartamento: '95',
      codMunicipio: '95,001',
      municipio: 'San José del Guaviare',
      Lat: '2.5683333',
      Lng: '-72.6416667',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,693',
      municipio: 'Santa Rosa de Viterbo',
      Lat: '5.9166667',
      Lng: '-73',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,698',
      municipio: 'Santander de Quilichao',
      Lat: '3.0130556',
      Lng: '-76.4866667',
    },
    {
      codRegion: '4',
      codDepartamento: '95',
      codMunicipio: '95,2',
      municipio: 'Miraflores',
      Lat: '1.3366667',
      Lng: '-71.9511111',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,042',
      municipio: 'Santafé de Antioquia',
      Lat: '5.75',
      Lng: '-73.5833333',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,68',
      municipio: 'San Carlos de Guaroa',
      Lat: '3.7122222',
      Lng: '-73.2436111',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,52',
      municipio: 'Palmar de Varela',
      Lat: '10.7466667',
      Lng: '-74.7555556',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,686',
      municipio: 'Santa Rosa de Osos',
      Lat: '6.6666667',
      Lng: '-75.4166667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,647',
      municipio: 'San Andrés de Cuerquía',
      Lat: '6917',
      Lng: '-75.667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,854',
      municipio: 'Valle de San Juan',
      Lat: '4.25',
      Lng: '-75.1666667',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,689',
      municipio: 'San Vicente de Chucurí',
      Lat: '6.3333333',
      Lng: '-75.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,684',
      municipio: 'San José de Miranda',
      Lat: '6.7',
      Lng: '-72.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '88',
      codMunicipio: '88,564',
      municipio: 'Providencia',
      Lat: '12.5',
      Lng: '-81.75',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,682',
      municipio: 'Santa Rosa de Cabal',
      Lat: '4.8680556',
      Lng: '-75.6213889',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,328',
      municipio: 'Guayabal de Siquima',
      Lat: '6.75',
      Lng: '-75.8333333',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,094',
      municipio: 'Belén de Los Andaquies',
      Lat: '5',
      Lng: '-75.8333333',
    },
    {
      codRegion: '4',
      codDepartamento: '85',
      codMunicipio: '85,25',
      municipio: 'Paz de Ariporo',
      Lat: '5.8833333',
      Lng: '-71.9',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,72',
      municipio: 'Santa Helena del Opón',
      Lat: '6.3375',
      Lng: '-73.5919444',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,681',
      municipio: 'San Pablo de Borbur',
      Lat: '5.75',
      Lng: '-74.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,42',
      municipio: 'La Jagua del Pilar',
      Lat: '10.5166667',
      Lng: '-73.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '20',
      codMunicipio: '20,4',
      municipio: 'La Jagua de Ibirico',
      Lat: '9.5641667',
      Lng: '-73.3375',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,742',
      municipio: 'San Luis de Sincé',
      Lat: '5.2805556',
      Lng: '-71.5819444',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,667',
      municipio: 'San Luis de Gaceno',
      Lat: '6',
      Lng: '-74.8333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,244',
      municipio: 'El Carmen de Bolívar',
      Lat: '9.75',
      Lng: '-75.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,245',
      municipio: 'El Carmen de Atrato',
      Lat: '5.89857199',
      Lng: '-76.14046083',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,702',
      municipio: 'San Juan de Betulia',
      Lat: '9.2755556',
      Lng: '-75.2455556',
    },
    {
      codRegion: '3',
      codDepartamento: '47',
      codMunicipio: '47,545',
      municipio: 'Pijiño del Carmen',
      Lat: '`9.3302778',
      Lng: '-74.4497222',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,873',
      municipio: 'Vigía del Fuerte',
      Lat: '6.5919444',
      Lng: '-76.8986111',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,667',
      municipio: 'San Martín de Loba',
      Lat: '8.8333333',
      Lng: '-73.9166667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,03',
      municipio: 'Altos del Rosario',
      Lat: '8.7944444',
      Lng: '-74.1658333',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,148',
      municipio: 'Carmen de Apicala',
      Lat: '7.7663889',
      Lng: '-76.6611111',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,645',
      municipio: 'San Antonio del Tequendama',
      Lat: '4.1333333',
      Lng: '-74.5',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,655',
      municipio: 'Sabana de Torres',
      Lat: '7.3980556',
      Lng: '-73.4969444',
    },
    {
      codRegion: '4',
      codDepartamento: '95',
      codMunicipio: '95,025',
      municipio: 'El Retorno',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,682',
      municipio: 'San José de Uré',
      Lat: '7767',
      Lng: '-75.517',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,694',
      municipio: 'San Pedro de Cartago',
      Lat: '1.5613889',
      Lng: '-77.1180556',
    },
    {
      codRegion: '3',
      codDepartamento: '8',
      codMunicipio: '8,137',
      municipio: 'Campo de La Cruz',
      Lat: '10.4166667',
      Lng: '-74.9166667',
    },
    {
      codRegion: '4',
      codDepartamento: '50',
      codMunicipio: '50,683',
      municipio: 'San Juan de Arama',
      Lat: '3.3463889',
      Lng: '-73.8894444',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,658',
      municipio: 'San José de La Montaña',
      Lat: '5.0858333',
      Lng: '-75.7883333',
    },
    {
      codRegion: '5',
      codDepartamento: '18',
      codMunicipio: '18,15',
      municipio: 'Cartagena del Chairá',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,66',
      municipio: 'San José del Palmar',
      Lat: '4.9166667',
      Lng: '-76.25',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,001',
      municipio: 'Agua de Dios',
      Lat: '4.4166667',
      Lng: '-74.6666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,655',
      municipio: 'San Jacinto del Cauca',
      Lat: '9.8316667',
      Lng: '-75.1263889',
    },
    {
      codRegion: '5',
      codDepartamento: '41',
      codMunicipio: '41,668',
      municipio: 'San Agustín',
      Lat: '1.9166667',
      Lng: '-76.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '52',
      codMunicipio: '52,258',
      municipio: 'El Tablón de Gómez',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '88',
      codMunicipio: '88,001',
      municipio: 'San Andrés',
      Lat: '6.8333333',
      Lng: '-72.75',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,664',
      municipio: 'San José de Pare',
      Lat: '6.0833333',
      Lng: '-73.4666667',
    },
    {
      codRegion: '5',
      codDepartamento: '86',
      codMunicipio: '86,865',
      municipio: 'Valle de Guamez',
      Lat: '8.3',
      Lng: '-76.1666667',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,67',
      municipio: 'San Pablo de Borbur',
      Lat: '5.75',
      Lng: '-74.0833333',
    },
    {
      codRegion: '3',
      codDepartamento: '70',
      codMunicipio: '70,82',
      municipio: 'Santiago de Tolú',
      Lat: '1.1511111',
      Lng: '-77.0075',
    },
    {
      codRegion: '2',
      codDepartamento: '11',
      codMunicipio: '11,001',
      municipio: 'Bogotá D.C.',
      Lat: '4.6',
      Lng: '-74.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,154',
      municipio: 'Carmen de Carupa',
      Lat: '5.3502778',
      Lng: '-73.9052778',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,189',
      municipio: 'Ciénaga de Oro',
      Lat: '9.4166667',
      Lng: '-74.7333333',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,659',
      municipio: 'San Juan de Urabá',
      Lat: '8.7630556',
      Lng: '-76.53',
    },
    {
      codRegion: '3',
      codDepartamento: '44',
      codMunicipio: '44,65',
      municipio: 'San Juan del Cesar',
      Lat: '10.8333333',
      Lng: '-73.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,235',
      municipio: 'El Carmen de Chucurí',
      Lat: '9.75',
      Lng: '-75.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,148',
      municipio: 'El Carmen de Viboral',
      Lat: '9.75',
      Lng: '-75.1666667',
    },
    {
      codRegion: '1',
      codDepartamento: '66',
      codMunicipio: '66,088',
      municipio: 'Belén de Umbría',
      Lat: '5',
      Lng: '-75.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '27',
      codMunicipio: '27,086',
      municipio: 'Belén de Bajira',
      Lat: '5',
      Lng: '-75.8333333',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,855',
      municipio: 'Valle de San José',
      Lat: '6.5',
      Lng: '-73.0666667',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,678',
      municipio: 'San Luis',
      Lat: '4.1666667',
      Lng: '-75.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,676',
      municipio: 'San Miguel de Sema',
      Lat: '5.55',
      Lng: '-73.75',
    },
    {
      codRegion: '5',
      codDepartamento: '73',
      codMunicipio: '73,675',
      municipio: 'San Antonio',
      Lat: '3.9166667',
      Lng: '-75.5',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,673',
      municipio: 'San Benito',
      Lat: '6.1666667',
      Lng: '-73.4666667',
    },
    {
      codRegion: '2',
      codDepartamento: '25',
      codMunicipio: '25,862',
      municipio: 'Vergara',
      Lat: '5.1666667',
      Lng: '-74.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '23',
      codMunicipio: '23,678',
      municipio: 'San Carlos',
      Lat: '8.7',
      Lng: '-75.7',
    },
    {
      codRegion: '5',
      codDepartamento: '91',
      codMunicipio: '91,53',
      municipio: 'Puerto Alegría',
      Lat: '0.9166667',
      Lng: '-77.25',
    },
    {
      codRegion: '2',
      codDepartamento: '68',
      codMunicipio: '68,344',
      municipio: 'Hato',
      Lat: '6.5833333',
      Lng: '-73.3333333',
    },
    {
      codRegion: '3',
      codDepartamento: '13',
      codMunicipio: '13,654',
      municipio: 'San Jacinto',
      Lat: '9.8333333',
      Lng: '-75.0833333',
    },
    {
      codRegion: '6',
      codDepartamento: '19',
      codMunicipio: '19,693',
      municipio: 'San Sebastián',
      Lat: '1.9166667',
      Lng: '-76.6666667',
    },
    {
      codRegion: '1',
      codDepartamento: '5',
      codMunicipio: '5,649',
      municipio: 'San Carlos',
      Lat: '8.7',
      Lng: '-75.7',
    },
    {
      codRegion: '2',
      codDepartamento: '15',
      codMunicipio: '15,837',
      municipio: 'Tuta',
      Lat: '1.1666667',
      Lng: '-77.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,743',
      municipio: 'Silos',
      Lat: '7.2',
      Lng: '-72.75',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,125',
      municipio: 'Cácota',
      Lat: '5.2666667',
      Lng: '-74.5666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,25',
      municipio: 'El Dovio',
      Lat: '4.5',
      Lng: '-76.25',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,82',
      municipio: 'Toledo',
      Lat: '7.3',
      Lng: '-72.25',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,622',
      municipio: 'Roldanillo',
      Lat: '4.4147222',
      Lng: '-76.1547222',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,48',
      municipio: 'Mutiscua',
      Lat: '6.7833333',
      Lng: '-72.6666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,054',
      municipio: 'Argelia',
      Lat: '4.7',
      Lng: '-76.1333333',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,261',
      municipio: 'El Zulia',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,66',
      municipio: 'Salazar',
      Lat: '7.8',
      Lng: '-72.8333333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,736',
      municipio: 'Sevilla',
      Lat: '4.2688889',
      Lng: '-75.9361111',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,895',
      municipio: 'Zarzal',
      Lat: '4.3983333',
      Lng: '-76.0772222',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,223',
      municipio: 'Cucutilla',
      Lat: '7.8833333',
      Lng: '-72.5052778',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,248',
      municipio: 'El Cerrito',
      Lat: '3.6666667',
      Lng: '-76.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,147',
      municipio: 'Cartago',
      Lat: '6.75',
      Lng: '-76',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,122',
      municipio: 'Caicedonia',
      Lat: '4.33365917459535',
      Lng: '-75.8342456817627',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,553',
      municipio: 'Puerto Santander',
      Lat: '8.3636111',
      Lng: '-72.4075',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,313',
      municipio: 'Gramalote',
      Lat: '7.9166667',
      Lng: '-72.75',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,246',
      municipio: 'El Cairo',
      Lat: '4.75',
      Lng: '-76.25',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,25',
      municipio: 'El Tarra',
      Lat: '4.9166667',
      Lng: '-76.0833333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,4',
      municipio: 'La Unión',
      Lat: '4.5358333',
      Lng: '-76.1066667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,606',
      municipio: 'Restrepo',
      Lat: '3.7833333',
      Lng: '-76.5',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,8',
      municipio: 'Teorama',
      Lat: '8.75',
      Lng: '-73.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,233',
      municipio: 'Dagua',
      Lat: '3.6602778',
      Lng: '-76.6927778',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,051',
      municipio: 'Arboledas',
      Lat: '7.6666667',
      Lng: '-72.75',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,318',
      municipio: 'Guacarí',
      Lat: '3.7694444',
      Lng: '-76.3380556',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,418',
      municipio: 'Lourdes',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,041',
      municipio: 'Ansermanuevo',
      Lat: '4.7972222',
      Lng: '-75.995',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,099',
      municipio: 'Bochalema',
      Lat: '7.6666667',
      Lng: '-72.5833333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,113',
      municipio: 'Bugalagrande',
      Lat: '4.2094444',
      Lng: '-76.1602778',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,206',
      municipio: 'Convención',
      Lat: '8.8333333',
      Lng: '-73.2',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,344',
      municipio: 'Hacarí',
      Lat: '8.5',
      Lng: '-73.0833333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,403',
      municipio: 'La Victoria',
      Lat: '4.5238889',
      Lng: '-76.0411111',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,347',
      municipio: 'Herrán',
      Lat: '7.5',
      Lng: '-72.4666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,306',
      municipio: 'Ginebra',
      Lat: '3.75',
      Lng: '-76.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,892',
      municipio: 'Yumbo',
      Lat: '3585',
      Lng: '-76.4958333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,497',
      municipio: 'Obando',
      Lat: '4.5833333',
      Lng: '-75.9166667',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,81',
      municipio: 'Tibú',
      Lat: '8.6480556',
      Lng: '-72.7394444',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,673',
      municipio: 'San Cayetano',
      Lat: '7.8833333',
      Lng: '-72.5833333',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,67',
      municipio: 'San Calixto',
      Lat: '8.75',
      Lng: '-73.0333333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,1',
      municipio: 'Bolívar',
      Lat: '4.3333333',
      Lng: '-76.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,398',
      municipio: 'La Playa',
      Lat: '8.25',
      Lng: '-73.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,001',
      municipio: 'Cali',
      Lat: '2.8333333',
      Lng: '-76.4166667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,67',
      municipio: 'San Pedro',
      Lat: '4',
      Lng: '-76.1666667',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,111',
      municipio: 'Guadalajara de Buga',
      Lat: '1',
      Lng: '-77.75',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,172',
      municipio: 'Chinácota',
      Lat: '7.75',
      Lng: '-72.55',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,599',
      municipio: 'Ragonvalia',
      Lat: '7.5833333',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,385',
      municipio: 'La Esperanza',
      Lat: '8.1666667',
      Lng: '-72.4666667',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,874',
      municipio: 'Villa del Rosario',
      Lat: '7.9169444',
      Lng: '-72.9763889',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,174',
      municipio: 'Chitagá',
      Lat: '7.1666667',
      Lng: '-72.5833333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,126',
      municipio: 'Calima',
      Lat: '7.4166667',
      Lng: '-72.85',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,72',
      municipio: 'Sardinata',
      Lat: '8.25',
      Lng: '-72.75',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,036',
      municipio: 'Andalucía',
      Lat: '4.1741667',
      Lng: '-76.1702778',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,563',
      municipio: 'Pradera',
      Lat: '3.4211111',
      Lng: '-76.2447222',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,003',
      municipio: 'Abrego',
      Lat: '5.75',
      Lng: '-75.4166667',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,405',
      municipio: 'Los Patios',
      Lat: '2.4333333',
      Lng: '-76.8',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,498',
      municipio: 'Ocaña',
      Lat: '8.25',
      Lng: '-73.3',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,109',
      municipio: 'Bucarasica',
      Lat: '8.0833333',
      Lng: '-73',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,89',
      municipio: 'Yotoco',
      Lat: '3.9166667',
      Lng: '-76.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,52',
      municipio: 'Palmira',
      Lat: '3.5394444',
      Lng: '-76.3036111',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,616',
      municipio: 'Riofrío',
      Lat: '4.0833333',
      Lng: '-76.3333333',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,68',
      municipio: 'Santiago',
      Lat: '1.1511111',
      Lng: '-77.0075',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,02',
      municipio: 'Alcalá',
      Lat: '4.6666667',
      Lng: '-75.75',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,863',
      municipio: 'Versalles',
      Lat: '4.6666667',
      Lng: '-76.25',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,377',
      municipio: 'Labateca',
      Lat: '7.3333333',
      Lng: '-72.5',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,128',
      municipio: 'Cachirá',
      Lat: '5.2666667',
      Lng: '-74.5666667',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,871',
      municipio: 'Villa Caro',
      Lat: '7.9169444',
      Lng: '-72.9763889',
    },
    {
      codRegion: '2',
      codDepartamento: '54',
      codMunicipio: '54,239',
      municipio: 'Durania',
      Lat: '7.75',
      Lng: '-72.6333333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,243',
      municipio: 'El Águila',
      Lat: '6.3333333',
      Lng: '-75.75',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,823',
      municipio: 'Toro',
      Lat: '4.6116667',
      Lng: '-76.0813889',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,13',
      municipio: 'Candelaria',
      Lat: '3.4130556',
      Lng: '-76.3511111',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,377',
      municipio: 'La Cumbre',
      Lat: '3.6666667',
      Lng: '-76.5833333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,845',
      municipio: 'Ulloa',
      Lat: '4.7166667',
      Lng: '-75.75',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,828',
      municipio: 'Trujillo',
      Lat: '4.25',
      Lng: '-76.3333333',
    },
    {
      codRegion: '6',
      codDepartamento: '76',
      codMunicipio: '76,869',
      municipio: 'Vijes',
      Lat: '3.6986111',
      Lng: '-76.4491667',
    },
  ];

  constructor() {}

  getMunicipiosSIG(): SelectItemGroup[] {
    const muniSI: SelectItemGroup[] = [];
    for (const depa of this.departamentos) {
      const si: SelectItemGroup = {
        label: depa.departamento,
        items: [],
      };
      const depaMuni = this.municipios.filter(
        (x) => x.codDepartamento === depa.codDepartamento
      );
      for (const muni of depaMuni) {
        si.items.push({
          label: muni.municipio,
          value: muni.codMunicipio,
        });
      }
      muniSI.push(si);
    }
    return muniSI;
  }

  getMunicipiosSI(): SelectItem[] {
    const muniSI: SelectItem[] = [];
    for (const depa of this.departamentos) {
      const depaMuni = this.municipios.filter(
        (x) => x.codDepartamento === depa.codDepartamento
      );
      for (const muni of depaMuni) {
        const si: SelectItem = {
          label: depa.departamento + ' - ' + muni.municipio,
          value: muni.codMunicipio,
        };
        muniSI.push(si);
      }
    }
    return muniSI;
  }
  getMunicipioName(id): string {
    const municipio = this.municipios.find((x) => x.codMunicipio === id);
    const departamento = this.departamentos.find(
      (x) => x.codDepartamento === municipio.codDepartamento
    );
    return departamento.departamento + ' - ' + municipio.municipio;
  }
}
