<div class="container-integra">
  <div class="p-grid p-fluid p-justify-end">
    <div class="p-col-12">
      <h1>Lista de Comités</h1>
    </div>
  </div>
  <div class="p-grid p-fluid p-justify-end">
    <div class="p-col-12 p-md-6 p-xl-3">
      <button
        pButton
        label="Crear comité"
        type="button"
        routerLink="/committee/add"
        icon="pi pi-plus"
      ></button>
    </div>
  </div>

  <!-- TABLA -->
  <div class="p-col-12">
    <p-table
      *ngIf="committees.length > 0"
      #dt
      [columns]="columns"
      [value]="committees"
      [globalFilterFields]="[
        'nombre',
        'tipoComite',
        'periodicidadReunionesCEI',
        'municipio',
        'direccion',
        'resolucionInvima',
        'informacionContacto'
      ]"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} comités"
      [rowsPerPageOptions]="[10, 25, 50]"
      [autoLayout]="true"
      sortMode="multiple"
    >
      <ng-template pTemplate="caption">
        <div class="table-header p-grid p-jc-between">
          <span></span>
          <span class="p-col p-lg-4">
            <i class="pi pi-search" style="margin-right: 10px"></i>
            <input
              style="width: 90%"
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Filtro global"
            />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.key">
            {{ col.value }}
            <p-sortIcon [field]="col.key"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr class="prewrap_text_justified">
          <td style="text-align: center; cursor: pointer">
            <p-menu
              #menu
              [popup]="true"
              [model]="items"
              appendTo="body"
            ></p-menu>
            <p
              class="pi pi-ellipsis-v"
              (click)="selectSponsor(rowData)"
              (click)="menu.toggle($event)"
            ></p>
          </td>
          <td *ngFor="let col of columns">{{ rowData[col.key] }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No se encontraron resultados.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-messages
      *ngIf="committees.length === 0"
      [(value)]="msgs"
      [closable]="false"
    ></p-messages>
  </div>
</div>
