<div class="container-integra">
    <div class="p-grid p-fluid p-justify-end">
        <div class="p-col-12">
            <h1>Lista de Usuarios</h1>
        </div>
        <div class="p-col-12 p-md-6 p-xl-4">
            <button pButton label="Crear usuario" type="button" (click)="openCreateUserModal()" icon="pi pi-plus"></button>
        </div>
    </div>

    <!-- TABLA -->
    <div class="p-col-12" *ngIf="users.length > 0">
        <p-table #dt [columns]="columns" [value]="users" [globalFilterFields]="['username', 'name', 'email', 'identificacion', 'phone_number', 'patrocinador', 'estado', 'rol']" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} usuarios"
            [rowsPerPageOptions]="[10,25,50]" [autoLayout]="true" sortMode="multiple">
            <ng-template pTemplate="caption">
                <div class="table-header p-grid p-jc-between">
                    <span></span>
                    <span class="p-col p-lg-4">
            <i class="pi pi-search" style="margin-right: 10px;"></i>
            <input style="width: 90%;" pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Filtro global" />
          </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th></th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.key">
                        {{col.value}}
                        <p-sortIcon [field]="col.key"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td style="text-align: center; cursor: pointer;">
                        <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                        <p class="pi pi-ellipsis-v" (click)="selectUser(rowData)" (click)="menu.toggle($event)"></p>
                    </td>
                    <td *ngFor="let col of columns">
                        {{rowData[col.key]}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-messages *ngIf="users.length === 0" [(value)]="msgs" [closable]="false">DDD</p-messages>
