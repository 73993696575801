<div>
    <div class="p-col-12" style="text-align: left">
        <a type="button" style="width: 100%;" pButton icon="pi pi-download" label="Descargar Archivo" class="ui-button" target="_blank" [href]="fileSrc" download></a>
    </div>
    <div style="width: 100%;" *ngIf="fileSrcView">
        <img style="width: 100%;" [src]="fileSrc">
    </div>
    <div style="width: 100%;" *ngIf="filePDFView">
        <embed [src]="fileSrc" type="application/pdf" width="100%" height="600px">
    </div>
</div>