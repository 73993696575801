<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm" class="form-style">
  <div class="p-grid p-fluid">
    <div class="p-offset-1 p-col-11" style="margin-top: 1rem">
      <a routerLink="/login">
        <i class="pi pi-chevron-left"> </i> Iniciar sesión
      </a>
    </div>
    <div class="p-offset-1 p-col-11">
      <h2>Recuperar Clave</h2>
      <h4 [hidden]="!step">Por favor ingresa su usuario</h4>
      <h4 [hidden]="step">
        Por favor ingresa el código que te llego a su email y la nueva clave
      </h4>
    </div>

    <div class="p-col-10 p-offset-1 p-lg-4" [hidden]="!step">
      <h4>Usuario</h4>
      <input
        name="usuario"
        type="text"
        pInputText
        [(ngModel)]="userId"
        [required]="step"
        #usuario="ngModel"
        [required]="!step"
      />
      <small
        class="form-error"
        *ngIf="!usuario.valid && (f.submitted || usuario.touched)"
        >* Campo requerido</small
      >
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4" [hidden]="step">
      <h4>Código de activación</h4>
      <input
        name="code"
        type="text"
        pInputText
        [(ngModel)]="activationCode"
        [required]="!step"
        #code="ngModel"
      />
      <small
        class="form-error"
        *ngIf="!code.valid && (f.submitted || code.touched)"
        >* Campo requerido</small
      >
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4" [hidden]="step">
      <h4>Contraseña</h4>
      <div class="p-inputgroup">
        <input
          name="clave"
          type="password"
          pPassword
          [(ngModel)]="newPassword"
          [showPassword]="isPasswordShown"
          [required]="!step"
          [feedback]="false"
          pTooltip="La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caractér especial"
          tooltipPosition="top"
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <h4 style="margin-top: 10px">Confirmación Contraseña</h4>
      <div class="p-inputgroup">
        <input
          name="clave"
          type="password"
          pPassword
          [(ngModel)]="passwordConfirmation"
          [showPassword]="isPasswordShown"
          [feedback]="false"
          [required]="!step"
          #pass2="ngModel"
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <small
        class="form-error"
        *ngIf="
          pass2.errors &&
          pass2.errors.required &&
          (f.submitted || pass2.touched)
        "
        >* Campo requerido</small
      >
    </div>
    <div class="p-col-10 p-offset-1 p-lg-2 p-lg-offset-8">
      <button
        pButton
        label="Enviar código"
        type="submit"
        class="p-button-secondary"
        *ngIf="step"
      ></button>
      <button
        pButton
        label="Cambiar contraseña"
        type="submit"
        class="p-button-secondary"
        *ngIf="!step"
      ></button>
    </div>
  </div>
</form>
