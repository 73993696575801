<div class="title">
    <span class="title1">Para el registro del proyecto</span>
    <span>tenga en cuenta la siguiente información</span>
</div>
<div class="p-d-flex p-mt-6 p-flex-wrap p-flex-md-row p-flex-column">
    <div style="flex: 1.5;">
        <ul>
            <li><span>1.</span> <strong> Antes de iniciar,</strong> haga click en cada uno de los pasos para conocer la información requerida para el registro del proyecto.</li>
            <li><span>2.</span> Es importante tener la información a la mano.</li>
        </ul>

        <strong class="p-mt-6 p-d-block">Gracias por su participación.</strong>
    </div>
    <div class="cardSteps p-ai-start p-jc-end p-jc-sm-center p-d-flex p-flex-wrap" style="flex: 1.5;">
        <div class="infoTile">
            <span class="number">1</span>
            <strong class="nombreStep">Datos de identificación</strong>
        </div>
        <div class="infoTile">
            <span class="number">2</span>
            <strong class="nombreStep">Propiedades del estudio</strong>
        </div>
        <div class="infoTile">
            <span class="number">3</span>
            <strong class="nombreStep">Datos internacionales / nacionales del estudio</strong>
        </div>
    </div>
</div>
<div class="p-d-flex p-jc-end p-py-6 p-pr-2">
    <p-button class="btnIniciar" label="INICIAR" type="button" (click)="skipIntro()"></p-button>
</div>