<div class="p-col-12">
  <p-table
    appendTo="body"
    [value]="dataPreview"
    [paginator]="data.length > 0"
    [rows]="10"
    [showCurrentPageReport]="true"
    #dt
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} estudios"
    [rowsPerPageOptions]="[10, 25, 50]"
    [filterDelay]="0"
    [autoLayout]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="validatedError">
          Estado de la carga
          <p-sortIcon field="validatedError"></p-sortIcon>
        </th>
        <th *ngFor="let d of itemsTable" [pSortableColumn]="d">
          {{ d | transforHeadersTable: source }}
          <p-sortIcon [field]="d"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-dataPreview>
      <tr>
        <td>
          <span
            class="p-tag p-tag-danger"
            *ngIf="!dataPreview.state.validatedError"
          >
            {{ dataPreview.state.action }} - Error
          </span>
          <span
            class="p-tag p-tag-success"
            *ngIf="
              dataPreview.state.validatedError &&
              dataPreview.state.action === 'Crear'
            "
          >
            {{ dataPreview.state.action }} - Ok
          </span>
          <span
            class="p-tag p-tag-warning"
            *ngIf="
              dataPreview.state.validatedError &&
              dataPreview.state.action === 'Actualizar'
            "
          >
            {{ dataPreview.state.action }} - Ok
          </span>
        </td>

        <td *ngFor="let data of dataPreview.data">
          <p *ngFor="let x of data; let primero = first">
            <span
              [ngStyle]="{
                color: !primero ? 'red' : 'black'
              }"
            >
              {{ transforDate(x) }}
            </span>
          </p>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
