<div class="container-integra">
  <h2>Tiempos promedios patrocinadores / INVIMA</h2>
  <app-filter-chart
    [sponsorFilterView]="true"
    (filter)="Filter($event, 'filtro1')"
  ></app-filter-chart>
  <app-time-bar-chart
    [dataAverageInvimaAllSponsor]="dataChartAverageInvimaAllSponsor"
    [dataAverageInvimaSpecificSponsor]="dataChartAverageInvimaSpecificSponsor"
    [idCanvas]="'filtro1'"
  ></app-time-bar-chart>

  <h2>Fechas inicio de estudios</h2>
  <app-filter-chart
    [sponsorFilterView]="true"
    (filter)="Filter($event, 'filtro2')"
  ></app-filter-chart>
  <app-time-bar-chart
    [dataInitTimeAllSponsor]="dataChartInitTimeAllSponsor"
    [dataInitTimeSpecificSponsor]="dataChartInitTimeSpecificSponsor"
    [idCanvas]="'filtro2'"
  ></app-time-bar-chart>

  <h2>Tiempos promedios patrocinadores / comités</h2>
  <app-filter-chart
    [committeeFilterView]="true"
    (filter)="Filter($event, 'filtro3')"
  ></app-filter-chart>
  <app-time-bar-chart
    [dataAverageCenterAllSponsor]="dataChartAverageCenterAllSponsor"
    [dataAverageCenterSpecificSponsor]="dataChartAverageCenterSpecificSponsor"
    [idCanvas]="'filtro3'"
  ></app-time-bar-chart>
</div>
