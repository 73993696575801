<form name="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="p-grid p-fluid">
    <div class="p-offset-1 p-col-11" style="margin-top: 1rem">
      <a routerLink="/login"> <i class="pi pi-chevron-left"> </i> Atras </a>
    </div>
    <div class="p-offset-1 p-col-11">
      <h2>Asigna la nueva contraseña</h2>
    </div>
    <div class="p-col-10 p-offset-1 p-lg-4">
      <h4>Contraseña</h4>
      <div class="p-inputgroup">
        <input
          name="pass1"
          type="password"
          pPassword
          [(ngModel)]="password"
          [showPassword]="isPasswordShown"
          required
          [feedback]="false"
          #pass1="ngModel"
          pTooltip="La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caractér especial"
          tooltipPosition="top"
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <small
        class="form-error"
        *ngIf="
          pass1.errors &&
          pass1.errors.required &&
          (f.submitted || pass1.touched)
        "
        >* Campo requerido</small
      >

      <h4 style="margin-top: 10px">Confirmación Contraseña</h4>
      <div class="p-inputgroup">
        <input
          name="pass2"
          type="password"
          pPassword
          [(ngModel)]="passwordConfirmation"
          [showPassword]="isPasswordShown"
          [feedback]="false"
          required
          #pass2="ngModel"
        />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="showPasswords()"
          *ngIf="!isPasswordShown"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          (click)="showPasswords()"
          *ngIf="isPasswordShown"
        ></button>
      </div>
      <small
        class="form-error"
        *ngIf="
          pass2.errors &&
          pass2.errors.required &&
          (f.submitted || pass2.touched)
        "
        >* Campo requerido</small
      >
    </div>
    <div class="p-col-10 p-offset-1 p-md-4 p-md-offset-6 p-lg-3 p-lg-offset-8">
      <button
        pButton
        label="Asignar contraseña"
        type="submit"
        class="p-button-secondary"
      ></button>
    </div>
  </div>
</form>
